export default {
  "recover_password_email_send": {
    "fr-FR": "Si votre adresse e-mail correspond bien à un compte existant et actif, vous recevrez le lien de changement de mot de passe",
    "en-GB": "You will receive a link to reset your password if your e-mail address matches an existing and active account",
    "nl-BE": "U ontvangt een link om uw wachtwoord opnieuw in te stellen als uw e-mailadres overeenkomt met een bestaand en actief account", //Google Translate
    "de-DE": "Sie erhalten einen Link zum Zurücksetzen Ihres Passworts, wenn Ihre E-Mail-Adresse mit einem bestehenden und aktiven Konto übereinstimmt" //Google Translate
  },
  "renew_password_email_send": {
    "fr-FR": "Un email a été envoyé à l'adresse renseignée dans votre compte",
    "en-GB": "An email has been sent to your account email address",
    "nl-BE": "Er is een e-mail verzonden naar het e-mailadres van uw account",
    "de-DE": "Eine E-Mail wurde an die E-Mail-Adresse Ihres Kontos gesendet"
  },
  "account_change_email_same_already_present": {
    "fr-FR": "Un compte avec cette adresse e-mail existe déjà",
    "en-GB": "An account with this email address already exists",
    "nl-BE": "Er bestaat al een account met dit e-mailadres",
    "de-DE": "Ein Konto mit dieser E-Mail-Adresse existiert bereits"
  },
  "empty_field_not_allowed": {
    "fr-FR": "La valeur du champ ne peut pas être vide",
    "en-GB": "The value of the field cannot be empty",
    "nl-BE": "De waarde van het veld mag niet leeg zijn",
    "de-DE": "Der Wert des Feldes darf nicht leer sein"
  },
  "required_field": {
    "fr-FR": "Ce champ est requis",
    "en-GB": "This field is required",
    "nl-BE": "Dit veld is verplicht",
    "de-DE": "Dieses Feld ist erforderlich"
  },
  "string_min": {
    "fr-FR": "Le champs doit avoir une taille minimum de %expected%",
    "en-GB": "The field must have a minimum size of %expected%",
    "nl-BE": "Het veld moet een minimale grootte van %expected% hebben",
    "de-DE": "Das Feld muss eine Mindestgröße von %expected% haben"
  },
  "validator_date_unknow": {
    "fr-FR": "Ce champ n'est pas une date",
    "en-GB": "This field is not a date", //traduction google
    "nl-BE": "Dit veld is geen datum", // traduction google
    "de-DE": "Dieses Feld ist kein Datum" //traduction google
  },
  "validator_number_field_positive_value_error": {
    "fr-FR": "La valeur du champs doit être supérieure à zéro",
    "en-GB": "The value of the field must be greater than zero",
    "nl-BE": "De waarde van het veld moet groter zijn dan nul",
    "de-DE": "Der Wert des Feldes muss größer als Null sein"
  },
  "validator_number_field_value_required" : {
    "fr-FR": "La valeur du champs doit être un nombre",
    "en-GB": "The field value must be a number",
    "nl-BE": "De veldwaarde moet een getal zijn",
    "de-DE": "Der Feldwert muss eine Zahl sein"
  },
  "incorrect_email_format": {
    "fr-FR": "Format d'email incorrect",
    "en-GB": "Incorrect email format",
    "nl-BE": "Onjuist e-mailformaat",
    "de-DE": "Falsches E-Mail-Format"
  },
  "validator_error_stringLength": {
    "fr-FR": "La longueur du champ doit être de %expected% caractères",
    "en-GB": "The length of the field must be %expected% characters", //Traduction Copilot
    "nl-BE": "De lengte van het veld moet %expected% tekens zijn", //Traduction Copilot
    "de-DE": "Die Länge des Feldes muss %expected% Zeichen betragen" //Traduction Copilot
  },
  "validator_error_stringEnum" : {
    "fr-FR": "La valeur du champ n'est pas autorisée",
    "en-GB": "The value of the field is not allowed", //Traduction Copilot
    "nl-BE": "De waarde van het veld is niet toegestaan", //Traduction Copilot
    "de-DE": "Der Wert des Feldes ist nicht erlaubt" //Traduction Copilot
  },
  "account_deleted_confirmation": {
      "fr-FR": "Votre compte a été supprimé",
      "en-GB": "Your account has been deleted",
      "nl-BE": "Uw account is verwijderd",
      "de-DE": "Ihr Konto wurde gelöscht"
  },
  "issue_followed": {
    "fr-FR": "Vous suivez maintenant ce signalement",
    "en-GB": "You are now following this report",
    "nl-BE": "Je volgt nu dit rapport",
    "de-DE": "Sie verfolgen jetzt diesen Bericht"
  }, 
  "issue_unfollowed": {
    "fr-FR": "Vous ne suivez plus ce signalement",
    "en-GB": "You are no longer following this report",
    "nl-BE": "Je volgt dit rapport niet meer",
    "de-DE": "Sie folgen diesem Bericht nicht mehr"
  },
  "message_sent": {
    "fr-FR": "Votre message a bien été envoyé",
    "en-GB": "Your message has been sent",
    "nl-BE": "Je bericht is verzonden",
    "de-DE": "Ihre Nachricht wurde gesendet"
  },   
  "file_service_error_file_format": {
    "fr-FR": "Le fichier %name% ne possède pas un format supporté par l'application",
    "en-GB": "The file %name% does not have a format supported by the application",
    "nl-BE": "Het bestand %name% heeft geen formaat dat door de applicatie wordt ondersteund",
    "de-DE": "Die Datei %name% hat kein von der Anwendung unterstütztes Format"
  },
  "file_service_error_file_size": {
    "fr-FR": "Le fichier %name% est trop volumineux",
    "en-GB": "`The %name% file is too large",
    "nl-BE": "Het %name% bestand is te groot",
    "de-DE": "`Die Datei %name% ist zu groß"
  },
  "any_changes_form": {
    "fr-FR": "Aucun changement sur le formulaire",
    "en-GB": "No change on the form",
    "nl-BE": "Geen wijziging op het formulier",
    "de-DE": "Keine Änderung am Formular"
  },
  "updateCgu_citizen_error_update": {
    "fr-FR": "Erreur lors de la validation des conditions générales d'utilisation",
    "en-GB": "Error while validating the Terms of Service",//traduction chatGPT
    "nl-BE": "Fout bij het valideren van de algemene voorwaarden",//traduction chatGPT
    "de-DE": "Fehler bei der Bestätigung der Allgemeinen Geschäftsbedingungen"//traduction chatGPT
  },
  issues_state_open: "Ouvert",
  issues_state_planned: "Planifié",
  issues_state_in_progress: "En traitement",
  issues_state_resolved: "Résolu",
  issues_state_transferred: "Transféré",
  issues_state_rejected: "Rejeté",
  issues_state_deleted: "Supprimé",
  issues_state_merged: "Fusionné",
  assignments_state_to_do: "À faire",
  assignments_state_ongoing: "En cours",
  assignments_state_finished: "Terminée",
  incorrect_field_pattern: {
    "fr-FR": "Le format du champ est incorrect",
    "en-GB": "The format of the field is incorrect",
    "nl-BE": "De indeling van het veld is onjuist",
    "de-DE": "Das Format des Feldes ist falsch"
  },
  user_helper_invitation_send: "Message d'invitation envoyé à %fullname%",
  user_helper_invitation_no_mail_error: "Invitation d'un utilisateur sans adresse e-mail"
}