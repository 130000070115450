import { Key, ModelBuilder }      from "@uLib/model";
import stdRepositoryConnector     from "@uLib/stdRepositoryConnector";

export default (application) => {

  const defaultUserValue = {
    authentificationProviders: [{
      discriminator: "username",
      username: "",
      password: ""
    }],
    email: "",
    disabled: false,
    tenants: []
  }

  return ModelBuilder.create("User", Key.create("_id"), stdRepositoryConnector(application, "users"), defaultUserValue)
    .add(p => p("_id", "string"))
    .add(p => p("discriminator", "string"))
    .add(p => p("anonymized", "bool"))
    .add(p => p("emailValidated", "bool"))
    .add(p => p("application", "string"))
    .add(p => p("team", "entity").type("Team"))
    .add(p => p("disabled", "bool"))
    .add(p => p("firstname", "string"))
    .add(p => p("lastname", "string"))
    .add(p => p("fullname", "string"))
    .add(p => p("avatar", "entity").type("File"))
    .add(p => p("tenant", "entity").type("Tenant"))
    .add(p => p("tenants", "array")
      .of(t => t("object")
        .add(p => p("disabled", "bool"))
        .add(p => p("roles", "array").of("string"))
        .add(p => p("buildings", "object")
          .add(p => p("ids", "array").of(t => t("entity").type("Building")))
          .add(p => p("tags", "array").of(t => t("string")))
        )
        .add(p => p("tenant", "entity").type("Tenant"))
        .add(p => p("tablet", "object")
          .add(p => p("allowed", "bool"))
        )
        .add(p => p("connector", "object"))
      )
    )
    .add(p => p("authentificationProviders", "array")
      .of(t => t("object")
        .add(p => p("discriminator", "string"))
        .add(p => p("username", "string"))
        .add(p => p("password", "string"))
      )
    )
    .add(p => p("lastConnection", "date"))
    .add(p => p("lastInvitationAt", "date"))
    .add(p => p("limitations", "object")
      .add(p => p("geographicalAreas", "array").of("string"))
      .add(p => p("cityCodeFilter", "string"))
      .add(p => p("tenants", "array").of(t => t("entity").type("Tenant")))
    )
    .add(p => p("$hasValorization", "bool"))
    .add(p => p("valorization", "int"))
    .add(p => p("email", "string"))
    .add(p => p("roles", "array").of("string"))
    .add(p => p("skills", "array").of("string"))
    .add(p => p("createdAt", "date"))
    .add(p => p("createdBy", "entity").type("User"))
    .add(p => p("hasPin", "bool"))
    .add(p => p("managedCategories", "entity").type("Category").link("_id", "redirectRule.manager", true).virtual())
    .add(p => p("createdIssueIsPublic", "bool"))
    .build();
}