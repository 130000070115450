import { Service } from "@uLib/application";
import _ from 'lodash';

export default class ApiService extends Service {
  constructor() {
    super("api", ["configuration", "http", "session"]);
    this._setHttpContext = this._setHttpContext.bind(this);
  }

  _setHttpContext() {
    return Promise.resolve()
      .then(() => {
        const opt = {
          url: this.application.getService("configuration").get("api_uri"),
          headers: {}
        }
        const token = this.application.getService("session").token;
        if(token){
          opt.headers.Authorization = 'Bearer ' + token;
          if(this.application.hasService("currentTenant") && this.application.getService("currentTenant").isSelected()){
            opt.headers.tenant = this.application.getService("currentTenant").currentId;
          }
        }
        return opt;
      });
  }

  async start(application) {
    const HttpRequest = application.getService("http");
    this._api = {
      "absences":  Object.assign(restfulCRUD(HttpRequest, this._setHttpContext, "absences"), {
        searchByPeriods: HttpRequest.create(this._setHttpContext, `/absences/searchByPeriods/`).method("GET").type("application/json").call((periods, users, tenant) => {
          const query = {
            periods: JSON.stringify(periods),
            tenant
          };
          if(users){
            query.users = JSON.stringify(users);
          }
          return { query };
        }).build()
      }),
      "buildings" : Object.assign(restfulCRUD(HttpRequest, this._setHttpContext, "buildings"), {
        "places": HttpRequest.create(this._setHttpContext, `/buildings/places`).method("GET").type("application/json").call((query, sort, offset, limit) => {
          const params = {};
          if (query) {
            params.q = JSON.stringify(query);
          }
          if (sort) {
            params.sort = JSON.stringify(sort);
          }
          if (offset) {
            params.offset = offset;
          }
          if (limit) {
            params.limit = limit;
          }
          return {query: params};
        }).build(),
      }),
      "campaigns" : Object.assign(restfulCRUD(HttpRequest, this._setHttpContext, "campaigns"), {
        current: HttpRequest.create(this._setHttpContext, "/campaigns/current").method("GET").type("application/json").call(() => ({})).build(),
        inform: HttpRequest.create(this._setHttpContext, "/campaigns/inform").method("POST").type("application/json").call((campaign, responseId) => ({
          body: {
            campaign, 
            responseId
          }
        })).build(),
      }),
      "comments"  : Object.assign(restfulCRUD(HttpRequest, this._setHttpContext, "comments"), {
        
      }),
      "equipments": Object.assign(restfulCRUD(HttpRequest, this._setHttpContext, "equipments"), {
        "import" : HttpRequest.create(this._setHttpContext, `/equipments/import`).method("POST").type("application/json").call(() => {
          return {};
        }).build(),
        "isAvailable" : HttpRequest.create(this._setHttpContext, `/equipments/availability`).method("GET").type("application/json").call((equipments, start, end, ignoredAssignments) => {
          return { query: { 
            equipments: JSON.stringify(equipments),
            start,
            end,
            ignoredAssignments: JSON.stringify(ignoredAssignments) 
          }};
        }).build(),
      }),
      "tenants"   : Object.assign(restfulCRUD(HttpRequest, this._setHttpContext, "tenants"), {
        inviteNotClient: HttpRequest.create(this._setHttpContext, `/tenants/:id/inviteNotClient`).method("POST").type("application/json").call((id, defaultEmail, type) => {
          return {
            path: { id },
            body: {
              defaultEmail,
              type
            }
          };
        }).build(),
        inviteSelf: HttpRequest.create(this._setHttpContext, `/tenants/:id/inviteSelf`).method("POST").type("application/json").call((id) => {
          return {
            path: { id },
            body: { id },
          };
        }).build()            
      }),
      "recurrences"   : Object.assign(restfulCRUD(HttpRequest, this._setHttpContext, "recurrences"), {
        "execute" : HttpRequest.create(this._setHttpContext, `/recurrences/:id/execute`).method("POST").type("application/json").call(id => {
          return {
            path: { id }
          };
        }).build(),
      }),
      "mapLayers" : Object.assign(restfulCRUD(HttpRequest, this._setHttpContext, "mapLayers"), {
        
      }),
      "releases"   : Object.assign(restfulCRUD(HttpRequest, this._setHttpContext, "releases"), {
        "publish" : HttpRequest.create(this._setHttpContext, `/releases/:releaseId/publish`).method("POST").type("application/json").call(releaseId => {
          return {
            path: { releaseId }
          };
        }).build(),
        "addFeature": HttpRequest.create(this._setHttpContext, `/releases/:releaseId/newFeatures`).method("POST").type("application/json").call((releaseId, feature) => {
          return {
            path: { releaseId },
            body: feature
          };
        }).build(),
        "updateFeature": HttpRequest.create(this._setHttpContext, `/releases/:releaseId/newFeatures/:featureId`).method("PUT").type("application/json").call((releaseId, featureId, feature) => {
          return {
            path: { releaseId, featureId },
            body: feature
          };
        }).build(),
        "removeNewFeature": HttpRequest.create(this._setHttpContext, `/releases/:releaseId/newFeatures/:featureId`).method("DELETE").type("application/json").call((releaseId, featureId) => {
          return {
            path: { releaseId, featureId }
          };
        }).build(),
        "addBugFixed": HttpRequest.create(this._setHttpContext, `/releases/:releaseId/bugFixed`).method("POST").type("application/json").call((releaseId, bugFixed) => {
          return {
            path: { releaseId },
            body: bugFixed
          };
        }).build(),
        "updateBugFixed": HttpRequest.create(this._setHttpContext, `/releases/:releaseId/bugFixed/:bugFixedId`).method("PUT").type("application/json").call((releaseId, bugFixedId, bugFixed) => {
          return {
            path: { releaseId, bugFixedId },
            body: bugFixed
          };
        }).build(),
        "removeBugFixed": HttpRequest.create(this._setHttpContext, `/releases/:releaseId/bugFixed/:bugFixedId`).method("DELETE").type("application/json").call((releaseId, bugFixedId) => {
          return {
            path: { releaseId, bugFixedId }
          };
        }).build(),
        "lastSeen": HttpRequest.create(this._setHttpContext, `/releases/:application/:platform/lastSeen`).method("GET").type("application/json").call((application, platform) => {
          return {
            path: { application, platform }
          };
        }).build(),
        "updateLastSeen": HttpRequest.create(this._setHttpContext, `/releases/:application/:platform/lastSeen`).method("PUT").type("application/json").call((application, platform, release) => {
          return {
            path: { application, platform },
            body: { release }
          };
        }).build(),
        "rotate": HttpRequest.create(this._setHttpContext, `/releases/:releaseId/rotate`).method("PUT").type("application/json").call((releaseId, type, elements) => {
          return {
            path: { releaseId },
            body: { 
              type,
              elements
            }
          };
        }).build(),
      }),
      "users"     : Object.assign(restfulCRUD(HttpRequest, this._setHttpContext, "users"), {
        "invite": HttpRequest.create(this._setHttpContext, `/users/:id/invite`).method("POST").type("application/json").call(id => {
          return {
            path: { id }
          };
        }).build(),
        "inviteCitizen": HttpRequest.create(this._setHttpContext, `/users/:id/inviteCitizen`).method("POST").type("application/json").call(id => {
          return {
            path: { id }
          };
        }).build(),
        "resetPassword": HttpRequest.create(this._setHttpContext, `/users/:id/resetPassword`).method("POST").type("application/json").call((id, password) => {
          return {
            path: { id },
            body: { password }
          };
        }).build(),
        "skills": HttpRequest.create(this._setHttpContext, `/users/skills`).method("GET").type("application/json").call((tenant, contain, different, limit) => {
          let query = {};
          if (tenant) {
            query.tenant = tenant;
          }
          if (contain) {
            query.contain = contain;
          }
          if (limit) {
            query.limit = limit;
          }
          if(different && different.length){
            query.different = JSON.stringify(different);
          }      
          return {
            query: query
          };
        }).build(),
        "setPin": HttpRequest.create(this._setHttpContext, `/users/:id/setPin`).method("POST").type("application/json").call((id, pin) => {
          return {
            path: { id },
            body: { pin }
          };
        }).build(),
        "resetPin": HttpRequest.create(this._setHttpContext, `/users/:id/resetPin`).method("POST").type("application/json").call(id => {
          return {
            path: { id }
          };
        }).build(),        
        "updateCgu" : HttpRequest.create(this._setHttpContext,`/users/:id/updateCgu`).method("PUT").type("application/json").call((id, version) =>{
          return {
            path:{ id },
            body:{ version }
          };
        }).build(),
        "usernameAvailable": HttpRequest.create(this._setHttpContext, `/users/usernameAvailable`).method("GET").type("application/json").call((username) => ({ query: { username }})).build(),
      }),
      "logs"      : Object.assign(restfulCRUD(HttpRequest, this._setHttpContext, "logs"), {
        "assignmentsPrint": HttpRequest.create(this._setHttpContext, `/logs/assignmentsPrint`).method("POST").type("application/json").call((query, sort, limit) => {
          return { body: { query, sort, limit }};
        }).build()
      }),
      "contacts"  : Object.assign(restfulCRUD(HttpRequest, this._setHttpContext, "contacts"), {
        
      }),
      "supplies"  : Object.assign(restfulCRUD(HttpRequest, this._setHttpContext, "supplies"), {
        
      }),
      "filters"  : Object.assign(restfulCRUD(HttpRequest, this._setHttpContext, "filters"), {
        
      }),
      "categories": Object.assign(restfulCRUD(HttpRequest, this._setHttpContext, "categories"),{
          getPublicSpaceByPosition: HttpRequest.create(this._setHttpContext, `/categories/publicSpace/byPosition`).method("POST").type("application/json").call((position) => {
            return {body: {
                position
              }};
          }).build(),
          updateManyManager:  HttpRequest.create(this._setHttpContext, `/categories`).method("PUT").type("application/json").call((categoriesToManagers) => {
            return {body: {
                categoriesToManagers
            }};
          }).build(),
          createMany:  HttpRequest.create(this._setHttpContext, `/categories/createMany`).method("POST").type("application/json").call((categories) => {
            return {body: categories };
          }).build(),
      }),
      "teams"     : Object.assign(restfulCRUD(HttpRequest, this._setHttpContext, "teams"), {
      }),
      "files"     : Object.assign(restfulCRUD(HttpRequest, this._setHttpContext, "files"), {
        "prepare": HttpRequest.create(this._setHttpContext, `/files/:id/prepare`).method("POST").type("application/json").call((id, size) => {
            return { path: { id }, body: { size }};
          }).build(),
        "upload": HttpRequest.create(this._setHttpContext, `/files/:id/upload/:startIndex/:length`).method("POST").type("application/octet-stream", {
          in : v => JSON.parse(v),
          out: v => v
        }).call((id, buffer, startIndex) => {
          return {
            path: { id, startIndex, length: buffer.byteLength },
            body: buffer
          };
        }).build(),
        "finalize": HttpRequest.create(this._setHttpContext, `/files/:id/finalize`).method("POST").type("application/json").call((id) => {
            return { path: { id }};
          }).build(),
        "meta": HttpRequest.create(this._setHttpContext, `/files/:id/meta`).method("GET").type("application/json").call((id) => {
          return { path: { id: id } };
        }).build(),
        "limitations": HttpRequest.create(this._setHttpContext, `/files/limitations`).method("GET").type("application/json").build()
      }),
      "assignments": restfulCRUD(HttpRequest, this._setHttpContext, "issues/assignments"),
      "issues"    : Object.assign(restfulCRUD(HttpRequest, this._setHttpContext, "issues"), {
        "light" : HttpRequest.create(this._setHttpContext, `/issues/light`).method("GET").type("application/json").call((query) => {
          return {
            query: { q: JSON.stringify(query) }
          };
        }).build(),
        "updateState": HttpRequest.create(this._setHttpContext, `/issues/:id/state`).method("PUT").type("application/json").call((idIssue, state, internalComment, publicComment) => ({ 
          path: {
            id: idIssue
          },
          body: {
            state,
            internalComment,
            publicComment,
          }
        })).build(),
        "countAll" : HttpRequest.create(this._setHttpContext, `/issues/countall`).method("GET").type("application/json").build(),
        "createIssueAndAssignment": HttpRequest.create(this._setHttpContext, "/issues/assignments").method("POST").type("application/json").call((assignment) => {
          return {
            body: assignment
          }
        }).build(),
        "createAssignment": HttpRequest.create(this._setHttpContext, `/issues/:id/assignments/`).method("POST").type("application/json").call((id, assignment) => {
          return {
            path: { id },
            body: assignment
          }
        }).build(),
        "setAssignment": HttpRequest.create(this._setHttpContext, `/issues/:id/assignments/:idAssignment`).method("PATCH").type("application/json").call((id, idAssignment, assignment) => {
          return {
            path: { id, idAssignment },
            body: assignment
          }
        }).build(),
        "deleteAssignment": HttpRequest.create(this._setHttpContext, `/issues/:id/assignments/:idAssignment`).method("DELETE").type("application/json").call((id, idAssignment) => {
          return {
            path: { id, idAssignment }
          }
        }).build(),
        "assignBulk": HttpRequest.create(this._setHttpContext, `/issues/assignments`).method("PUT").type("application/json").call((issues, assignment) => {
          return {
            body: {
              issues,
              assignment
            }
          };
        }).build(),
        "bulkUpdate": HttpRequest.create(this._setHttpContext, `/issues/bulkUpdate`).method("PATCH").type("application/json").call((issues, datas) => {
          return {
            body: {
              issues,
              ...datas
            }
          };
        }).build(),
        "setAssignmentProgress": HttpRequest.create(this._setHttpContext, '/issues/:id/assignments/:idAssignment/progress').method("POST").type("application/json").call((id, idAssignment, progress, comment, files, valorization) => {
          return {
            path: { id, idAssignment },
            body: { progress, comment, files, valorization }
          }
        }).build(),
        "addFile": HttpRequest.create(this._setHttpContext, `/issues/:id/files`).method("POST").type("application/json").call((id, fileId) => {
          return {
            path: { id },
            body: { file: fileId }
          }
        }).build(),
        "removeFile": HttpRequest.create(this._setHttpContext, `/issues/:id/files/:fileId`).method("DELETE").type("application/json").call((id, fileId) => {
          return {
            path: { id, fileId }
          }
        }).build(),
        "setCover": HttpRequest.create(this._setHttpContext, `/issues/:id/files/:fileId/setCover`).method("POST").type("application/json").call((id, fileId) => {
          return {
            path: { id, fileId }
          }
        }).build(), 
        "follow": HttpRequest.create(this._setHttpContext, `/issues/:id/follow`).method("PUT").type("application/json").call((id) => {
          return {
            path: { id }
          }
        }).build(),
        "unmerge": HttpRequest.create(this._setHttpContext, `/issues/:id/unmerge`).method("PUT").type("application/json").call((id) => {
          return {
            path: { id }
          }
        }).build(),
        "unfollow": HttpRequest.create(this._setHttpContext, `/issues/:id/unfollow`).method("PUT").type("application/json").call((id) => {
          return {
            path: { id }
          }
        }).build(),
        "createPublicComment": HttpRequest.create(this._setHttpContext, `/issues/:id/comments/public`).method("PUT").type("application/json").call((id, comment) => {
          return {
            path: { id },
            body: {
              message: comment
            }
          }
        }).build(),
        "createPrivateComment": HttpRequest.create(this._setHttpContext, `/issues/:id/comments/private`).method("PUT").type("application/json").call((id, comment) => {
          return {
            path: { id },
            body: {
              message: comment
            }
          }
        }).build(),
        "getComments": HttpRequest.create(this._setHttpContext, `/issues/:id/comments`).method("GET").type("application/json").call((id) => {
          return {
            path: { id }
          }
        }).build(),
        "addFile": HttpRequest.create(this._setHttpContext, `/issues/:id/files`).method("POST").type("application/json").call((id, fileId) => {
          return {
            path: { id },
            body: {
              file: fileId
            }
          }
        }).build(),
        "nears": HttpRequest.create(this._setHttpContext, `/issues/nears`).method("GET").type("application/json").call((position, query = {}, limit, offset) => {
          const params = {};
          params.position = JSON.stringify(position);
          if (query) {
            params.q = JSON.stringify(query);
          }
          if (offset) {
            params.offset = offset;
          }
          if (limit) {
            params.limit = limit;
          }
          return {query: params};
        }).build(),
        "statistics": HttpRequest.create(this._setHttpContext, "/issues/statistics").method("POST").type("application/json").call((period, query) => {
          const body = {};
          if(period){
            body.period = period;
          }
          if(query){
            body.query = query;
          }else{
            body.query = {};
          }
          return {
            body
          };
        }).build(),
        "tags": HttpRequest.create(this._setHttpContext, `/issues/tags`).method("GET").type("application/json").call((query, sort, offset, limit) => {
          const params = {};
          if (query) {
            params.q = JSON.stringify(query);
          }
          if (sort) {
            params.sort = JSON.stringify(sort);
          }
          if (offset) {
            params.offset = offset;
          }
          if (limit) {
            params.limit = limit;
          }
          return {query: params};
        }).build(),
        "localities": HttpRequest.create(this._setHttpContext, `/issues/findTenantLocalities`).method("GET").type("application/json").call(tenant => ({ query: { tenant } })).build(),
        "zipCodes": HttpRequest.create(this._setHttpContext, `/issues/findTenantZipCodes`).method("GET").type("application/json").call(tenant => ({ query: { tenant }})).build(),
        "icalUrl": HttpRequest.create(this._setHttpContext, `/issues/icalUrl`).method("GET").type("application/json").call((query) => ({ 
          query: {
            query: JSON.stringify(query)
          }
        })).build(),
      }),
      "issueGroups": {
        get   : getBuilder(HttpRequest, this._setHttpContext, "issues/groups"),
        count : countBuilder(HttpRequest, this._setHttpContext, "issues/groups"),
        getOne: getOneBuilder(HttpRequest, this._setHttpContext, "issues/groups"),
      },
      "sessions": {
        "login": HttpRequest.create(this._setHttpContext, `/login`).method("POST").type("application/json").call( (username, password, type) => {
            if (username && password) 
              return { body: { username, password, type }};
            else 
              return { body: username };
            }
          ).build(),
        "loginAs": HttpRequest.create(this._setHttpContext, `/loginAs`).method("POST").type("application/json").call( (userId) => ({ body: { userId }})).build(),
        "tabletLogin": HttpRequest.create(this._setHttpContext, `/tabletLogin`).method("POST").type("application/json").call( (userId, pin) => {
          return { body: { userId, pin }};
        }).build(),
        "recoverPassword": HttpRequest.create(this._setHttpContext, `/sendAuthTokenByMail`).method("POST").type("application/json").call((email, discriminator) => {
              return { body: {  discriminator, email }};
            }
          ).build(),
        "renewToken": HttpRequest.create(this._setHttpContext, `/login`).method("GET").type("application/json").build(),
        "acl": HttpRequest.create(this._setHttpContext, `/acl`).method("GET").type("application/json").build(),
        "time": HttpRequest.create(this._setHttpContext, `/time`).method("GET").type("application/json").build(),
        "me": HttpRequest.create(this._setHttpContext, `/me`).method("GET").type("application/json").build(),
        "avatar": HttpRequest.create(this._setHttpContext, `/me/avatar`).method("GET").type("application/json").build(),
        "icalUrl": HttpRequest.create(this._setHttpContext, `/me/icalUrl`).method("GET").type("application/json").call((query) => ({ query: {query: JSON.stringify(query) }})).build(),
        "getGeoDatasToken": HttpRequest.create(this._setHttpContext, `/me/geoDatasToken`).method("GET").type("application/json").build(),
        "deleteAccount": HttpRequest.create(this._setHttpContext, `/me/deleteAccount`).method("POST").type("application/json").build(),
        "renewPassword": HttpRequest.create(this._setHttpContext, `/me/renewPassword`).method("POST").type("application/json").build(),
        "updateMyPassword": HttpRequest.create(this._setHttpContext, `/me/updatePassword`).method("POST").type("application/json").call((password) => {
            return { body: { password }};
          }).build(),
        "updateMine": HttpRequest.create(this._setHttpContext, `/me`).method("POST").type("application/json").call((user) => {
            return { body: user };
          }).build(),
        "search": HttpRequest.create(this._setHttpContext, `/search`).method("GET").type("application/json").call((value, tenant) => {
          return { query: { value, tenant }};
        }).build(),
        "registerUserOnMobileApp": HttpRequest.create(this._setHttpContext, `/me/mobileToken`).method("POST").type("application/json").call((token, os) => {
          return { body: { token, os }};
        }).build(),
        "unregisterUserOnMobileApp": HttpRequest.create(this._setHttpContext, `/me/mobileToken/:token`).method("DELETE").type("application/json").call((token) => {
          return { path: { token }};
        }).build(),
        "getTokenAssistance": HttpRequest.create(this._setHttpContext, `/tokenAssistance`).method("GET").type("application/json").build(),
        "updateSubscriptions": HttpRequest.create(this._setHttpContext, `/me/subscriptions`).method("PUT").type("application/json").call((subscriptions) => {
          return { body: { subscriptions }};
        }).build(),
      }, 
      "configuration": {
        "get": HttpRequest.create(this._setHttpContext, `/configuration`).method("GET").type("application/json").build(),
        "getForForm": HttpRequest.create(this._setHttpContext, `/configuration/forForm`).method("GET").type("application/json").build(),
        "put": HttpRequest.create(this._setHttpContext, `/configuration`).method("PUT").type("application/json").call((config) => ({ body: config })).build(),
      },
      notifications: {
        get: HttpRequest.create(this._setHttpContext, `/notifications/`).method("GET").type("application/json").call((query, offset, limit) => ({
          query: {
            q: JSON.stringify(query),
            offset,
            limit,
          }
        })).build(),
        seen: HttpRequest.create(this._setHttpContext, `/notifications/seen`).method("PUT").type("application/json").build(),
        read: HttpRequest.create(this._setHttpContext, `/notifications/:notificationId/read`).method("PUT").type("application/json").call((notificationId) => ({
          path: {
            notificationId
          }
        })).build(),
        readAll: HttpRequest.create(this._setHttpContext, `/notifications/read`).method("PUT").type("application/json").build(),
      },
      "providers": {
        "getHorizonGpEntities": HttpRequest.create(this._setHttpContext, `/providers/horizon/gp/entities`).method("GET").type("application/json").call(( tenant ) => ({ query: { tenant }})).build(),
        "getHorizonGpServices": HttpRequest.create(this._setHttpContext, `/providers/horizon/gp/services`).method("GET").type("application/json").call(( tenant, entity ) => ({ query: { tenant, entity }})).build(),
        "getHorizonGpAgents": HttpRequest.create(this._setHttpContext, `/providers/horizon/gp/agents`).method("GET").type("application/json").call(( tenant, entity, service ) => ({ query: { tenant, entity, service }})).build(),
      },
      "valorizations"     : {
        get: getBuilder(HttpRequest, this._setHttpContext, "valorizations"),
        "createOnIssue": HttpRequest.create(this._setHttpContext, `/issues/:idIssue/valorizations`).method("POST").type("application/json").call((valorization) => ({ 
          body: _.omit(valorization, ['issue', 'assignment']),
          path: { idIssue: valorization.issue }
        })).build(),
        "createOnAssignment": HttpRequest.create(this._setHttpContext, `/issues/:idIssue/assignments/:idAssignment/valorizations`).method("POST").type("application/json").call((valorization) => ({ 
          body: _.omit(valorization, ['issue', 'assignment']),
          path: {
            idIssue: valorization.issue,
            idAssignment: valorization.assignment
          }
        })).build(),
        "updateOnIssue": HttpRequest.create(this._setHttpContext, `/issues/:idIssue/valorizations/:idValorization`).method("PUT").type("application/json").call((valorization) => ({ 
          body: _.omit(valorization, ['issue', 'assignment', '_id']),
          path: { 
            idIssue: valorization.issue,
            idValorization: valorization._id
          }
        })).build(),
        "updateOnAssignment": HttpRequest.create(this._setHttpContext, `/issues/:idIssue/assignments/:idAssignment/valorizations/:idValorization`).method("PUT").type("application/json").call((valorization) => ({ 
          body: _.omit(valorization, ['issue', 'assignment', '_id']),
          path: {
            idIssue: valorization.issue,
            idAssignment: valorization.assignment,
            idValorization: valorization._id
          }
        })).build(),
        "deleteOnIssue": HttpRequest.create(this._setHttpContext, `/issues/:idIssue/valorizations/:idValorization`).method("DELETE").type("application/json").call((valorization) => ({ 
          path: { 
            idIssue: valorization.issue,
            idValorization: valorization._id
          }
        })).build(),
        "deleteOnAssignment": HttpRequest.create(this._setHttpContext, `/issues/:idIssue/assignments/:idAssignment/valorizations/:idValorization`).method("DELETE").type("application/json").call((valorization) => ({ 
          path: {
            idIssue: valorization.issue,
            idAssignment: valorization.assignment,
            idValorization: valorization._id
          }
        })).build(),
      },
      "starterActivation": {
        "sendConfirmEmail": HttpRequest.create(this._setHttpContext, `/starterActivation/sendConfirmEmail`).method("POST").type("application/json").call((params) => ({ 
          body: params
        })).build(),
        "unsubscribe": HttpRequest.create(this._setHttpContext, `/starterActivation/unsubscribe`).method("POST").type("application/json").call(({ reasons, noCitizenEmail }) => ({ 
          body: { 
            reasons, 
            noCitizenEmail 
          }
        })).build(),
      }
    };
  }
  has(resourceName, actionName){
    return !!this._api[resourceName] && !!this._api[resourceName][actionName];
  }
  service(resourceName, actionName){
    if(!this.has(resourceName, actionName)){
      console.error(`unknow api.service("${resourceName}", "${actionName}")`);
      return { execute: () => Promise.reject({ message: `unknow api.service("${resourceName}", "${actionName}")`}) };
    }
    return this._api[resourceName][actionName];
  }
  set(resourceName, actionName, handler){
    let oldHandler = this._api[resourceName][actionName];
    this._api[resourceName][actionName] = handler;
    return oldHandler;
  }

  create(resourceName, actionName, path, builderHandler) {
    if(!this._api[resourceName]){
      this._api[resourceName] = {};
    }

    const HttpRequest = application.getService("http");
    this._api[resourceName][actionName] = builderHandler(HttpRequest.create(this._setHttpContext, path)).build();
  }
};

const getBuilder = (HttpRequest, httpContext, namespace, postCreate = (v => v)) => {
  return postCreate(HttpRequest.create(httpContext, `/${namespace}`).method("GET").type("application/json").call((query, sort, offset, limit) => {
    const params = {};
    if (query) {
      params.q = JSON.stringify(query);
    }
    if (sort) {
      params.sort = JSON.stringify(sort)
    }
    if (offset) {
      params.offset = offset;
    }
    if (limit) {
      params.limit = limit;
    }
    return {query: params};
  }).build());
};

const countBuilder = (HttpRequest, httpContext, namespace, postCreate = (v => v)) => {
  return postCreate(HttpRequest.create(httpContext, `/${namespace}/count`).method("GET").type("application/json").call((query) => {
    const params = {};
    if (query) {
      params.q = JSON.stringify(query);
    }
    return {query: params};
  }).build());
};


const getOneBuilder = (HttpRequest, httpContext, namespace, postCreate = (v => v)) => {
  return postCreate(HttpRequest.create(httpContext, `/${namespace}/:id`).method("GET").type("application/json").call((id) => {
    return {path: {
        id
      }};
  }).build());
};

const postBuilder = (HttpRequest, httpContext, namespace, postCreate = (v => v)) => {
  return postCreate(HttpRequest.create(httpContext, `/${namespace}`).method("POST").type("application/json").call((object) => {
    return {body: object}
  }).build());
};

const putBuilder = (HttpRequest, httpContext, namespace, postCreate = (v => v)) => {
  return postCreate(HttpRequest.create(httpContext, `/${namespace}/:id`).method("PUT").type("application/json").call((id, object) => {
    return {path: {
        id
      }, body: object}
  }).build());
};

const patchBuilder = (HttpRequest, httpContext, namespace, postCreate = (v => v)) => {
  return postCreate(HttpRequest.create(httpContext, `/${namespace}/:id`).method("PATCH").type("application/json").call((id, object) => {
    return {path: {
        id
      }, body: object}
  }).build());
};

const deleteBuilder = (HttpRequest, httpContext, namespace, postCreate = (v => v)) => {
  return postCreate(HttpRequest.create(httpContext, `/${namespace}/:id`).method("DELETE").type("application/json").call((id) => {
    return {path: {
        id
      }}
  }).build());
};

const restfulCRUD = (HttpRequest, httpContext, namespace, postCreate = (v => v)) => {
  return {
    get   : getBuilder(HttpRequest, httpContext, namespace, postCreate),
    count : countBuilder(HttpRequest, httpContext, namespace, postCreate),
    getOne: getOneBuilder(HttpRequest, httpContext, namespace, postCreate),
    post  : postBuilder(HttpRequest, httpContext, namespace, postCreate),
    put   : putBuilder(HttpRequest, httpContext, namespace, postCreate),
    patch : patchBuilder(HttpRequest, httpContext, namespace, postCreate),
    delete: deleteBuilder(HttpRequest, httpContext, namespace, postCreate)
  }
}
