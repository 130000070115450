import { Service }              from "@uLib/application";
import { WebNavigator }         from '../features/router';
import type { Navigator, Path }       from '../features/router';

export default class NavigatorService extends Service{
  private webNavitator: WebNavigator;
  constructor(){
    super("navigator");
    this.webNavitator = new WebNavigator();
  }
  async start(){
    this.webNavitator.initialize();
  }
  get navigator(): Navigator {
    return this.webNavitator;
  }
  get state(){
    return this.webNavitator.state;
  }
  push(url: Path, state: Object){
    if(!state){
      state = this.state;
    }
    this.webNavitator.goTo(url, state);
  }
  replace(url: Path, state?: Object){
    this.webNavitator.goTo(url, state, false);
  }
  go(url: Path, state?: Object, keepExistinginHistory: boolean = true){
    if(!state){
      state = this.state;
    }
    this.webNavitator.goTo(url, state, keepExistinginHistory);
  }
  back(){
    this.webNavitator.goBack();
  }
}