import Query from "@universal/types/technic/Query";
import dayjs from "dayjs";

class Accessor<Type> {
  private _path: (string | number)[];

  constructor(path: (string | number)[]) {
    this._path = path;
  }

  get path(): string {
    return this._path.join(".");
  }

  private _getValues(values: any[], property: string | number): any[] {
    return values.reduce((acc, value) => {
      if (!(value instanceof Object)) {
        return acc;
      }
      if (Array.isArray(value[property])) {
        return acc.concat(value[property]);
      }
      acc.push(value[property]);
      return acc;
    }, []);
  }

  extract(object: Type): any[] {
    return this._path.reduce((values, property) =>
      this._getValues(values, property)
      , [object]);
  }
}

export class Filter<Type> {
  toQuery(): Query<Type> {
    throw new Error("Must be override");
  }

  match = (object: Type): boolean => {
    throw new Error("Must be override");
  }

  flatten(): Filter<Type> {
    return this;
  }
}

class NoOperationFilter<Type> extends Filter<Type> {
  toQuery(): Query<Type> {
    return {};
  }

  match = (object: Type) => {
    return true;
  }
}

type Operator = {
  toQuery: <Type>(value: Type) => any,
  operate: (value1: any, value2: any) => boolean,
  cache?: <Type>(value: any) => any
}

function isValuableFilter<Type>(filter: Filter<Type>): filter is ValuableFilter<Type> {
  return filter instanceof ValuableFilter;
}

class ValuableFilter<Type> extends Filter<Type> {
  private _operator: Operator;

  private _accessor: Accessor<Type>;

  private _queryValue: any;

  private _testedValue: any[];

  constructor(operator: Operator, accessor: Accessor<Type>, testedValue: any) {
    super();
    this._operator = operator;
    this._accessor = accessor;
    this._queryValue = testedValue;
    if (operator.cache) {
      testedValue = operator.cache(testedValue);
    }
    this._testedValue = (Array.isArray(testedValue) ? testedValue : [testedValue]).map(value => this._convert(value));
  }

  get operator(): Operator {
    return this._operator;
  }

  get accessor(): Accessor<Type> {
    return this._accessor;
  }

  get queryValue(): any {
    return this.queryValue;
  }

  toQuery(): Query<Type> {
    return {
      [this._accessor.path]: this._operator.toQuery(this._queryValue)
    };
  }

  private _convert(value: any): any {
    if (value instanceof Date) {
      value = dayjs(value).toISOString();
    }
    return value;
  }

  match = (object: Type): boolean => {
    return this._accessor.extract(object).reduce((test, value) => {
      value = this._convert(value);
      return test || this._testedValue.some(testedValue => this._operator.operate(value, testedValue));
    }, false);
  }
}

type Agglomerator = {
  toQuery: <Type>(queries: Query<Type>[]) => Query<Type>,
  agglomerate: <Type>(filters: Filter<Type>[], object: Type) => boolean
};

class CompositeFilter<Type> extends Filter<Type> {
  private _filters: Filter<Type>[];

  private _agglomerator: Agglomerator;

  constructor(agglomerator: Agglomerator) {
    super();
    this._filters = [];
    this._agglomerator = agglomerator;
  }

  add(filter: Filter<Type>): boolean {
    if (this._filters.indexOf(filter) !== -1) {
      return false;
    }
    this._filters.push(filter);
    return true;
  }

  remove(filter: Filter<Type>): boolean {
    const idx = this._filters.indexOf(filter);
    if (idx === -1) {
      return false;
    }
    this._filters.splice(idx, 1);
    return true;
  }

  toQuery(): Query<Type> {
    return this._agglomerator.toQuery(this._filters.map(filter => filter.toQuery()));
  }

  match = (object: Type): boolean => {
    return this._agglomerator.agglomerate(this._filters, object);
  }

  flatten(): Filter<Type> {
    if (this._agglomerator === or) {
      return this;
    }
    const filters = this._filters.map(filter => filter.flatten());
    if (!filters.length) {
      return this;
    }
    let currentAccessor: Accessor<Type> | null = null;
    let values: any[] = [];
    
    const flattenable = filters.reduce((flattenable, filter) => {
      if (!flattenable || !isValuableFilter(filter)) {
        return false;
      }
      if (currentAccessor === null) {
        currentAccessor = filter.accessor;
      }
      if (filter.accessor.path !== currentAccessor.path || (filter.operator !== _in && filter.operator !== eq)) {
        return false;
      }

      values = values.concat(Array.isArray(filter.queryValue) ? filter.queryValue : [filter.queryValue]);

      return true;
    }, true);

    if (!flattenable || currentAccessor === null) {
      return this;
    }
    return new ValuableFilter(
      _in,
      currentAccessor,
      values
    );
  }
}

class NotFilter<Type> extends Filter<Type> {
  private _filter: Filter<Type>;

  constructor(filter: Filter<Type>) {
    super();
    this._filter = filter;
  }

  toQuery(): Query<Type> {
    return { $ne: this._filter.toQuery() };
  }

  match = (object: Type): boolean => {
    return !this._filter.match(object);
  }
}

class DiffFilter<Type> extends Filter<Type> {
  private _filter: Filter<Type>;

  private field: string;

  private value: any;

  constructor(field: string, value: any) {
    super();
    this.field = field;
    this.value = value;
    this._filter = new NotFilter(Criterion.create().eq(field, value));
  }

  match = (object: Type): boolean => {
    return this._filter.match(object);
  }

  toQuery(): Query<Type> {
    return {[this.field]: { $ne: this.value }};
  }
}

class NinFilter<Type> extends Filter<Type> {
  private _filter: Filter<Type>;

  private field: string;

  private value: any;

  constructor(field: string, value: any) {
    super();
    this.field = field;
    this.value = value;
    this._filter = new NotFilter(Criterion.create().in(field, value));
  }

  match = (object: Type): boolean => {
    return this._filter.match(object);
  }

  toQuery(): Query<Type> {
    return {[this.field]: { $nin: this.value }};
  }
}

const or: Agglomerator = {
  toQuery: <Type>(queries: Query<Type>[]): Query<Type> => (
    { $or: queries }
  ),
  agglomerate: <Type>(filters: Filter<Type>[], object: Type): boolean => filters.reduce<boolean>(
    (valid, filter) => valid || filter.match(object),
    false
  )
};
const nor: Agglomerator = {
  toQuery: <Type>(queries: Query<Type>[]): Query<Type> => (
    { $nor: queries }
  ),
  agglomerate: <Type>(filters: Filter<Type>[], object: Type): boolean => !filters.reduce<boolean>(
    (valid, filter) => valid || filter.match(object),
    false
  )
};
const and: Agglomerator = {
  toQuery: <Type>(queries: Query<Type>[]): Query<Type> => (
    { $and: queries }
  ),
  agglomerate: <Type>(filters: Filter<Type>[], object: Type): boolean => filters.reduce<boolean>(
    (valid, filter) => valid && filter.match(object),
    true
  )
};
const eq: Operator = {
  toQuery: <Type>(value: any): Query<Type> => value,
  operate: (value1: any, value2: any): boolean => {
    if(value2 === null){
      return value1 === null || value1 === undefined;
    }
    return value1 === value2;
  }
};
const gte: Operator = {
  toQuery: <Type>(value: any): Query<Type> => (
    { $gte: value }
  ),
  operate: (value1: any, value2: any): boolean => value1 >= value2
};
const gt: Operator = {
  toQuery: <Type>(value: any): Query<Type> => (
    { $gt: value }
  ),
  operate: (value1: any, value2: any): boolean => value1 > value2
};
const lt: Operator = {
  toQuery: <Type>(value: any): Query<Type> => (
    { $lt: value }
  ),
  operate: (value1: any, value2: any): boolean => value1 < value2
};
const lte: Operator = {
  toQuery: <Type>(value: any): Query<Type> => ({ $lte: value }),
  operate: (value1: any, value2: any): boolean => value1 <= value2
};
const regex: Operator = {
  toQuery: <Type>(value: any): Query<Type> => value,
  operate: (value1: any, regex: any): boolean => regex.test(value1),
  cache: (regex: { $regex: string, $options: string}) => new RegExp(regex.$regex, regex.$options)
};
const elemMatch: Operator = {
  toQuery: <Type>(query: any): Query<Type> => (
    { $elemMatch: query }
  ),
  operate: (value: any, filter: any): boolean => filter.match(value),
  cache: <Type>(query: Query<Type>) => Criterion.factory(query)
};
const _in: Operator = {
  toQuery: <Type>(query: any): Query<Type> => (
    { $in: query }
  ),
  operate: (value1: any, value2: any): boolean => {
    if(value2 === null){
      return value1 === null || value1 === undefined;
    }
    return value1 === value2;
  }
};
const exists: Operator = {
  toQuery: <Type>($exists: any): Query<Type> => (
    { $exists }
  ),
  operate: (value: any, $exists: any) => {
    return $exists
      ? value !== undefined
      : value === undefined
  }
};

class Criterion<Type> {
  static factory<Type>(query: Query<Type>): Filter<Type> {
    if (query instanceof Filter) {
      return query;
    }
    if(Object.keys(query).length === 0) {
      return new NoOperationFilter();
    }
    return this._recursiveFactory(Criterion.create<Type>(), query)
  }

  private static _recursiveFactory<Type>(criterion: Criterion<Type>, query: Query<Type>): Filter<Type> {
    const keys = Object.keys(query);
    if (!keys.length) {
      return new NoOperationFilter();
    }
    if (keys.length > 1) {
      return criterion.and(c => keys.map(key => {
        const tmpQuery: Query<Type> = {};
        tmpQuery[key] = query[key];
        return this._recursiveFactory(c, tmpQuery);
      }));
    }
    switch (keys[0]) {
      case "$and":
        return criterion.and(c => query.$and.map((q: Query<Type>) => this._recursiveFactory(c, q)));
      case "$or":
        return criterion.or(c => query.$or.map((q: Query<Type>) => this._recursiveFactory(c, q)));
      case "$nor":
        return criterion.nor(c => query.$nor.map((q: Query<Type>) => this._recursiveFactory(c, q)));
      default:
        const property = keys[0];
        const value = query[property];
        if (value === null) {
          return criterion.eq(property, null);
        }
        if (value.$ne !== undefined) {
          return criterion.diff(property, value.$ne);
        } else if (value.$lte !== undefined) {
          return criterion.lte(property, value.$lte);
        } else if (value.$lt !== undefined) {
          return criterion.lt(property, value.$lt);
        } else if (value.$gt !== undefined) {
          return criterion.gt(property, value.$gt);
        } else if (value.$gte !== undefined) {
          return criterion.gte(property, value.$gte);
        } else if (value.$regex) {
          return criterion.regex(property, value);
        } else if (value.$elemMatch) {
          return criterion.elemMatch(property, value.$elemMatch);
        } else if (value.$in || Array.isArray(value)) {
          return criterion.in(property, value.$in ? value.$in : value);
        } else if (value.$nin) {
          return criterion.nin(property, value.$nin);
        } else if (value.$exists !== undefined) {
          return criterion.exists(property, value.$exists);
        } else {
          return criterion.eq(property, value?.$eq ? value.$eq : value);
        }
    }
  }

  static create<Type>(): Criterion<Type> {
    return new Criterion<Type>();
  }

  _getPath(field: string | Function): string[] {
    if (field instanceof Function) {
      const path: string[] = [];
      const spy = {
        get(path: string[], propertyName: string) {
          path.push(propertyName);
          return p;
        }
      }
      const p = new Proxy(path, spy);
      field(p);
      return path;
    }
    if ("" + field === field) {
      return field.split(".");
    }
    throw new Error("Invalid field");
  }

  private _buildComposite(aggregator: Agglomerator, componentHandler: (criterion: Criterion<Type>) => Filter<Type>[]): CompositeFilter<Type> {
    const composite = new CompositeFilter<Type>(aggregator);
    componentHandler(this).forEach(filter => composite.add(filter));
    return composite;
  }

  or(componentHandler: (criterion: Criterion<Type>) => Filter<Type>[]): CompositeFilter<Type> {
    return this._buildComposite(or, componentHandler);
  }

  and(componentHandler: (criterion: Criterion<Type>) => Filter<Type>[]): CompositeFilter<Type> {
    return this._buildComposite(and, componentHandler);
  }

  nor(componentHandler: (criterion: Criterion<Type>) => Filter<Type>[]): CompositeFilter<Type> {
    return this._buildComposite(nor, componentHandler);
  }

  private _buildValuable(premise: Operator, field: string, value: any): ValuableFilter<Type> {
    const accessor = new Accessor(this._getPath(field));
    return new ValuableFilter<Type>(premise, accessor, value);
  }

  eq(field: string, value: any): ValuableFilter<Type> {
    return this._buildValuable(eq, field, value);
  }

  diff(field: string, value: any): DiffFilter<Type> {
    return new DiffFilter(field, value);
  }

  gte(field: string, value: any): ValuableFilter<Type> {
    return this._buildValuable(gte, field, value);
  }

  gt(field: string, value: any): ValuableFilter<Type> {
    return this._buildValuable(gt, field, value);
  }

  lt(field: string, value: any): ValuableFilter<Type> {
    return this._buildValuable(lt, field, value);
  }

  lte(field: string, value: any): ValuableFilter<Type> {
    return this._buildValuable(lte, field, value);
  }

  regex(field: string, value: any): ValuableFilter<Type> {
    return this._buildValuable(regex, field, value);
  }

  elemMatch(field: string, query: any): ValuableFilter<Type> {
    return this._buildValuable(elemMatch, field, query);
  }

  in(field: string, query: any): ValuableFilter<Type> {
    return this._buildValuable(_in, field, query);
  }

  nin(field: string, query: any): NinFilter<Type> {
    return new NinFilter(field, query);
  }

  exists(field: string, $exists: any): ValuableFilter<Type> {
    return this._buildValuable(exists, field, $exists);
  }
}

export default Criterion;
