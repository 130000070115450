import { Key, ModelBuilder }      from "@uLib/model";
import stdRepositoryConnector     from "@uLib/stdRepositoryConnector";

export default (application) => {
  const defaultTenantValue = {
    informations:{
      pictures : {}
    },
    name: {
      fr: "",
      en: "",
      nl: "",
      de: ""
    },
    country:"fr",
    settings:{
      issueResolvedWhenTaskCompleted:true,
      allowPublicSpaceIssue:true,
      mandatoryTagsOnCategories: false,
      holidayDays: {
        standarts: [],
        generics: []
      },
      workingDays: [],
      mainUser: null
    },
    locationType:"default",
    discriminator:"entity",
    connector: {
      gp: null
    }
  }

  return ModelBuilder.create("Tenant", Key.create("_id"), stdRepositoryConnector(application, "tenants"), defaultTenantValue)
    .add(p => p("_id", "string"))
    .add(p => p("discriminator", "string"))
    .add(p => p("name", "object")
      .add(p => p("fr", "string"))
      .add(p => p("en", "string"))
      .add(p => p("nl", "string"))
      .add(p => p("de", "string"))
    )
    .add(p => p("settings", "object")
      .add(p => p("allowPublicSpaceIssue", "bool"))
      .add(p => p("commercialOffer", "string"))
      .add(p => p("issueIsDefaultPublic", "bool"))
      .add(p => p("issueResolvedWhenTaskCompleted", "bool"))
      .add(p => p("publicSpace", "bool"))
      .add(p => p("citizen", "object")
        .add(p => p("allowed", "bool"))
        .add(p => p("defaultEmail", "string"))
        .add(p => p("displaySlavesIssues", "bool"))
      )
      .add(p => p("mainUser", "entity").type("User"))
      .add(p => p("usersProLimitedToSettings", "bool"))
    )
    .add(p => p("informations", "object")
      .add(p => p("postalCode", "array").of(t => t("string")))
      .add(p => p("siren", "string"))
      .add(p => p("pictures", "object")
        .add(p => p("logo", "entity").type("File"))
      )
      .add(p => p("webSitesUrls", "string"))
      .add(p => p("tenantSponsor", "string"))
      .add(p => p("nl", "string"))
    )  
    .add(p => p("country", "string"))
    .add(p => p("cityCode", "string"))
    .add(p => p("createdAt", "date"))
    .add(p => p("createdBy", "entity").type("User"))
    .add(p => p("clientIdJvs", "string"))
    .add(p => p("connector", "object")
      .add(p => p("gp", "object")
        .add(p => p("type", "string"))  
      )
    )
    .build();
}