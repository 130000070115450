export default {
  "all_city": {
    "fr-FR": "Toutes les communes",
    "en-GB": "All municipalities",
    "nl-BE": "Alle gemeenten",
    "de-DE": "Alle Gemeinden"
  },
  "all_issues": {
    "fr-FR": "Tous les signalements",
    "en-GB": "All issues",
    "nl-BE": "Alle problemen",
    "de-DE": "Alle Meldungen"
  },
  "issue": {
    "fr-FR": "Signalement",
    "en-GB": "Report",
    "nl-BE": "Melding",
    "de-DE": "Meldung"
  },
  "issue_detail_new_comment": {
    "fr-FR": "Commentaire (2000 caractères maximum)",
    "en-GB": "Comment (Max. 2000 characters)",
    "nl-BE": "Commentaar (Max. 2000 karakters)",
    "de-DE": "Kommentar (Maximal 2000 Zeichen)"
  },
  "about": {
    "fr-FR": "A propos",
    "en-GB": "About us",
    "nl-BE": "Over ons",
    "de-DE": "Über"
  },
  "jvs_betterstreet": {
    "fr-FR": "BetterStreet est une marque de la société Better Company SA (BE0502872942), une société du Groupe JVS",
    "en-GB": "BetterStreet is a trademark of Better Company SA (BE0502872942), a Group JVS company",
    "nl-BE": "BetterStreet is een merk van Better Company NV, een bedrijf van JVS Groep",
    "de-DE": "BetterStreet ist eine Marke der Better Company SA (BE0502872942), einem Unternehmen der JVS-Gruppe."
  },
  "betterstreet_condition_link": {
    "fr-FR": "Conditions de services et vie privée...",
    "en-GB": "Terms and conditions & Privacy...",
    "nl-BE": "Algemene voorwarden en persoonlijke gegevens...",
    "de-DE": "Servicebedingungen und Privatsphäre..."
  },
  "issue_follow": {
    "fr-FR": "Suivre",
    "en-GB": "Follow",
    "nl-BE": "Volgen",
    "de-DE": "Verfolgen"
  },
  "issues_filter_any_time": {
    "fr-FR": "Date indifférente",
    "en-GB": "Anytime",
    "nl-BE": "Elke period",
    "de-DE": "Beliebiger Zeitraum"
  },
  "issues_filter_less_than_an_hour": {
    "fr-FR": "Moins d'une heure",
    "en-GB": "Past hour",
    "nl-BE": "Afgelopen uur",
    "de-DE": "Weniger als eine Stunde"
  },
  "issues_filter_less_than_24_hour": {
    "fr-FR": "Moins de 24h",
    "en-GB": "Past 24hours",
    "nl-BE": "Afgelopen 24 uur",
    "de-DE": "Weniger als 24 Stunden"
  },
  "issues_filter_less_than_a_week": {
    "fr-FR": "Moins d'une semaine",
    "en-GB": "Past week",
    "nl-BE": "Afgelopen week",
    "de-DE": "Weniger als eine Woche"
  },
  "issues_filter_less_than_a_month": {
    "fr-FR": "Moins d'un mois",
    "en-GB": "Past month",
    "nl-BE": "Afgelopen maand",
    "de-DE": "Weniger als ein Monat"
  },
  "issues_filter_state_all_states": {
    "fr-FR": "Tous les statuts",
    "en-GB": "All states",
    "nl-BE": "Alle staten",
    "de-DE": "Alle Status"
  },
  "issues_list_empty": {
    "fr-FR": "Il n’y a actuellement aucun signalement public sur cette commune.",
    "en-GB": "There is currently no public request in this town.",
    "nl-BE": "Er zijn momenteel geen openbare rapporten in deze gemeente.", //Google Translate
    "de-DE": "Derzeit gibt es in dieser Gemeinde keine öffentlichen Berichte." //Google Translate
  },
  "issues_list_empty_instructions": {
    "fr-FR": "Vous pouvez en créer un depuis le menu “Nouveau signalement +”",
    "en-GB": "You can create a new one by using the ”New Request +” menu",
    "nl-BE": "U kunt er een maken via het menu “Nieuw rapport +”.", //Google Translate
    "de-DE": "Sie können einen über das Menü „Neuer Bericht +“ erstellen" //Google Translate
  },
  
  "mine_seeMyIssue": {
    "fr-FR": "Voir tous mes signalements",
    "en-GB": "My reports",
    "nl-BE": "Mijn meldingen",
    "de-DE": "Alle meine Meldungen ansehen"
  },
  "header_newIssue": {
    "fr-FR": "Nouveau signalement",
    "en-GB": "New Request",
    "nl-BE": "Nieuwe melding",
    "de-DE": "Neue Meldung"
  },
  "header_about": {
    "fr-FR": "À propos",
    "en-GB": "About us",
    "nl-BE": "Over ons",
    "de-DE": "Über"
  },
  "header_connect": {
    "fr-FR": "Se connecter",
    "en-GB": "Login",
    "nl-BE": "Inloggen",
    "de-DE": "Anmelden"
  },
  "about_title": {
    "fr-FR": "La gestion des interventions la plus conviviale, mobile et participative",
    "en-GB": "Make your life easier with our collaborative solution for public space and buildings",
    "nl-BE": "De collaboratieve oplossing voor leefmilieu en gebouwen beheer die uw leven vereenvoudigt",
    "de-DE": "Die benutzerfreundlichste, mobilste und am besten eingebundene Verwaltung von Maßnahmen"
  },
  "about_askbrochure": {
    "fr-FR": "Demander notre brochure",
    "en-GB": "Request our brochure",
    "nl-BE": "Vraag onze catalogus",
    "de-DE": "Unsere Broschüre anfordern"
  },
  "about_report_title": {
    "fr-FR": "Encoder",
    "en-GB": "Report",
    "nl-BE": "Melden",
    "de-DE": "Erstellung"
  },
  "about_report_content": {
    "fr-FR": "L’encodage dans BetterStreet est facilité par l’app mobile qui permet en quelques clics de créer une intervention avec une photo et une localisation précise depuis le terrain. L’encodage classique depuis un ordinateur est bien évidemment également disponible.",
    "en-GB": "The encoding in BetterStreet is facilitated by the mobile app which allows in a few clicks to create an intervention with a photo and a precise location from the field. The classic encoding from a computer is obviously also available.",
    "nl-BE": "Encoding in BetterStreet wordt vergemakkelijkt door de mobiele app die het mogelijk maakt een paar kliks om een ​​interventie met een foto en een precieze locatie van het veld te creëren. De klassieke encoding van een pc is natuurlijk ook beschikbaar.",
    "de-DE": "Die Erstellung in BetterStreet wird durch die mobile App erleichtert, mit der man mit wenigen Klicks eine Maßnahme mit einem Foto und der genauen Lage direkt vom Standort aus erstellen kann. Die herkömmliche Erstellung von einem Computer aus ist natürlich auch möglich."
  },
  "about_report_mobile_title": {
    "fr-FR": "Encodage en mode nomade",
    "en-GB": "Mobile app BetterStreet",
    "nl-BE": "Mobiele app BetterStreet",
    "de-DE": "Erstellung im mobilen Modus"
  },
  "about_report_mobile_content": {
    "fr-FR": "L’encodage d’une intervention par vos agents ou par vous même est d’une facilité déconcertante grâce à l’application mobile Expert de BetterStreet. Vous ajoutez une ou plusieurs photos qui seront automatiquement géo-localisées, vous décrivez l’intervention et le tour est joué. En temps réel ou plus tard au bureau, votre intervention est enregistrée dans le module central. Le superviseur est immédiatement informé de l’encodage d’une nouvelle intervention.",
    "en-GB": "With a smartphone, you report an issue in three simple steps by taking a picture and categorising it. The app completes the job by geo-localising where you are and « Send » is the only action you still have to make. In less than 30 seconds, you created a qualitative report that will be addressed directly to the right department. The only requirement is, prior to report, to create an account so that the local government can inform you about the status.",
    "nl-BE": "Dankzij onze mobiele app of direct op onze website, kan u in minder dan 30 seconden problemen of suggesties op een eenvoudige en gebruiksvriendelijke manier melden.Neem een foto, benoem de foto met de juiste categorie, voeg optioneel een opmerking toe en klik op 'Verzenden' en dat is het. De foto wordt automatisch gelokaliseerd via de GPS van uw smartphone.",
    "de-DE": "Die Erstellung einer Maßnahme durch Ihre Mitarbeiter oder durch Sie selbst ist dank der Expert-App von BetterStreet kinderleicht. Sie fügen ein oder mehrere Fotos hinzu, die automatisch geo-lokalisiert werden, beschreiben Ihre Maßnahme und fertig. In Echtzeit oder später im Büro wird Ihre Maßnahme im zentralen Modul erfasst. Der Vorgesetzte wird unmittelbar über die Erstellung einer neuen Maßnahme informiert."
  },
  "about_report_multi-channel_title": {
    "fr-FR": "Encodage multi-canal",
    "en-GB": "Multi-channel",
    "nl-BE": "Alle kanalen",
    "de-DE": "Erstellung über mehrere Kanäle"
  },
  "about_report_multi-channel_content": {
    "fr-FR": "L’encodage par des personnes de l’accueil ou d’autres services est très facile via le formulaire web interne. Que les demandes arrivent par courrier, email ou téléphone, elles sont encodées en quelques minutes par les agents administratifs de manière décentralisée. Les utilisateurs identifiés de bâtiments publics peuvent également introduire des demandes d’intervention pour leur bâtiment.",
    "en-GB": "Encoding by people from the welcome desk or other services is very easy via the internal web form. Whether the requests arrive by mail, email or telephone, they are encoded in a few minutes by the administrative agents in a decentralized way. Identified users of public buildings may also submit requests for assistance for their buildings.",
    "nl-BE": "De invoering door mensen vanuit gemeente loket of andere diensten is zeer eenvoudig via de interne webformulier. Alle aanvragen, ongeacht de ingang kanaal (e-mail, post, telefoon) worden gecodeerd door de administratieve agenten in BetterStreet. De geïdentificeerde gebruikers van openbare gebouwen kunnen ook aanvragen voor interventie in hun gebouw.",
    "de-DE": "Die Erstellung durch das Servicepersonal oder Mitarbeiter in anderen Abteilungen ist über das interne Webformular sehr einfach. Ganz gleich, ob die Anträge per Post, E-Mail oder Telefon eingehen, sie werden innerhalb weniger Minuten dezentral von den Verwaltungsmitarbeitern erstellt. Identifizierte Nutzer von öffentlichen Gebäuden können ebenfalls Anträge auf Mängelbeseitigung für ihr Gebäude stellen."
  },
  "about_centralization_title": {
    "fr-FR": "Centraliser",
    "en-GB": "Manage",
    "nl-BE": "Beheren",
    "de-DE": "Zentralisierung"
  },
  "about_centralization_content": {
    "fr-FR": "Quelque soit le canal utilisé (app, courrier, téléphone, etc..), BetterStreet permet de centraliser toutes les demandes et interventions pour le service technique, que celles-ci concernent l’espace public ou les bâtiments, à la fois curatives et préventives.",
    "en-GB": "Whatever the channel used (app, mail, telephone, etc ...), BetterStreet makes it possible to centralize all the requests and interventions for the technical service, whether these concern public space or buildings, both curative and preventive",
    "nl-BE": "Ongeacht het gebruikte kanaal (app, e-mail, telefoon, etc ..), BetterStreet centraliseert alle verzoeken en procedures voor de technische dienst, dat zij betrekking hebben op de openbare ruimte of gebouwen, zowel curatief als preventief .",
    "de-DE": "Unabhängig vom verwendeten Kanal (App, Post, Telefon usw.) ermöglicht BetterStreet die zentrale Erfassung aller Anträge und Maßnahmen für den technischen Dienst, unabhängig davon, ob sie den öffentlichen Raum oder Gebäude betreffen, und zwar sowohl kurativ als auch präventiv."
  },
  "about_centralization_one-place_title": {
    "fr-FR": "Centralisation de toutes les interventions",
    "en-GB": "Centralization of all interventions in one place",
    "nl-BE": "Centralisatie of all werken",
    "de-DE": "Zentralisierung aller Maßnahmen"
  },
  "about_centralization_one-place_content": {
    "fr-FR": "BetterStreet permet de centraliser l’ensemble des demandes et interventions dans l’espace public (voirie, propreté, signalisation, éclairage, etc…), dans les bâtiments (plomberie, menuiserie, peinture, etc…) ainsi que sur les véhicules ou équipements (entretien annuel, réparation). BetterStreet permet de gérer à la fois les interventions curatives et préventives.",
    "en-GB": "With BetterStreet, you centralize all interventions regarding public space (pothole, traffic lights, illegal dumping, etc..), public buildings and vehicles/equipements.",
    "nl-BE": "BetterStreet centraliseert alle verzoeken en interventies in de openbare ruimte (wegen, netheid, bewegwijzering, verlichting, etc ...), in gebouwen (sanitair, timmerwerk, schilderen, etc ...), alsmede voertuigen ( jaarlijks onderhoud, reparatie). BetterStreet beheert zowel curatieve en preventieve interventies.",
    "de-DE": "BetterStreet ermöglicht die zentrale Erfassung aller Anträge und Maßnahmen im öffentlichen Raum (Straßennetz, Sauberkeit, Beschilderung, Beleuchtung usw.), in Gebäuden (Klempner, Schreiner, Maler usw.) sowie an Fahrzeugen oder Geräten (jährliche Wartung, Reparatur). BetterStreet ermöglicht es, sowohl kurative als auch präventive Maßnahmen zu verwalten."
  },
  "about_centralization_list_title": {
    "fr-FR": "Aperçu des demandes",
    "en-GB": "Overview of the requests",
    "nl-BE": "Overzicht van alle meldingen",
    "de-DE": "Übersicht über die Anträge"
  },
  "about_centralization_list_content": {
    "fr-FR": "La commune dispose d’une vue globale de l’ensemble des problèmes introduits ainsi que de leur état d’avancement. La gestion est facilitée par des possibilités multiples de filtrage et triage. A tout moment, le responsable peut visionner les demandes ouvertes ou en-cours. L’interface conviviale et moderne permet une prise en main quasiment immédiate.",
    "en-GB": "You have at your disposal a list with all the issues introduced in your region. You can manage this list in a few clicks by selecting the filtering and sorting criterias. In a nutshell, you see what the problems are and what their status are with our colored interface.",
    "nl-BE": "Met onze gebruiksvriendelijke en gekleurde lijst, wordt het heel gemakkelijk een goeie overzicht te hebben van alle meldingen en hun status. De filters en de sortering functionaliteiten maken voor een eenvoudige behandeling van uw lijst.",
    "de-DE": "Die Gemeinde hat einen umfassenden Überblick über alle gemeldeten Probleme und deren Stand. Die Verwaltung wird durch vielfältige Möglichkeiten zum Filtern und Sortieren erleichtert. Zu jeder Zeit kann der Bearbeiter offene oder in Bearbeitung befindliche Anträge einsehen. Die benutzerfreundliche und moderne Oberfläche ermöglicht eine sehr schnelle Einarbeitung."
  },
  "about_centralization_map_title": {
    "fr-FR": "Cartographie des interventions",
    "en-GB": "Overview on a map",
    "nl-BE": "Overzicht van alle meldingen op een kaart",
    "de-DE": "Kartografie der Maßnahmen"
  },
  "about_centralization_map_content": {
    "fr-FR": "De nos jours, il arrive qu'on envoie une équipe à un endroit le matin et 20 mètres à côté le lendemain. Avec BetterStreet, cela n'arrivera plus car vous visionnez l'ensemble des demandes sur la carte de la commune. Vous pouvez ainsi grouper des interventions dans une même zone.",
    "en-GB": "Because all the public issues have a location, you can visualize all the requests on a map so that it becomes easy for you to either regroup activities or to detect points where the same problem occurs week after week.",
    "nl-BE": "Doordat alle meldingen gelokaliseerd zijn, kunt u een overzicht van alle meldingen op een kaart van de gemeente zien. Dat faciliteert het groepering van meldingen en dat is ook een manier om te zien waar de pijn punten zijn (bij voorbeeld sluikstorten altijd of dezelfde plaats).",
    "de-DE": "Manchmal kommt es vor, dass man morgens ein Team an einen Ort schickt und am nächsten Tag 20 Meter weiter. Mit BetterStreet passiert das nicht mehr, denn Sie sehen alle Anträge auf der Karte der Gemeinde. So können Sie Maßnahmen im selben Bereich gruppieren."
  },
  "about_planning_title": {
    "fr-FR": "Planifier",
    "en-GB": "Communication",
    "nl-BE": "Communiceren",
    "de-DE": "Planung"
  },
  "about_planning_content": {
    "fr-FR": "L’outil de planification de BetterStreet permet à chaque chef d’équipe de planifier ses activités au moyen d’un calendrier hebdomadaire ou mensuel. Les bons de travail permettent une communication précise vers les agents.",
    "en-GB": "The BetterStreet planning tool allows each team leader to plan his or her activities through a weekly or monthly schedule. Work orders allow accurate communication to agents.",
    "nl-BE": "De BetterStreet planning tool maakt het mogelijk elk teamleider om zijn activiteiten te plannen door middel van een wekelijks of maandelijks schema. Werkbon verzekeren een efficiente communicatie met de agenten.",
    "de-DE": "Mit dem Planungstool von BetterStreet kann jeder Teamleiter seine Aktivitäten mithilfe eines Wochen- oder Monatskalenders planen. Arbeitsauftragsformulare ermöglichen eine genaue Kommunikation mit den Mitarbeitern."
  },
  "about_planning_calendar_title": {
    "fr-FR": "Planification et organisation du travail",
    "en-GB": "Planning & Calendar",
    "nl-BE": "Planning en kalendar",
    "de-DE": "Planung und Organisation der Arbeit"
  },
  "about_planning_calendar_content": {
    "fr-FR": "Les tâches sont planifiées sur un agenda par de simples glisser-coller. La planification peut être réalisée à un niveau équipe ou individuellement agent par agent. L’affichage des ressources disponibles (par exemple l’absence de certains hommes ou l’utilisation de certains équipements) permet une planification efficace.",
    "en-GB": "The solution allows you to manage your teams and your tasks. You can print work order based on your instructions or you can assign the task to someone else inside the organisation.",
    "nl-BE": "Elke interventie wordt aan een arbeider of een ploeg toegewezen en een werkfiche kan afgedrukt worden. Indien nodig, kan de interventie ook naar een andere beheerder toegewezen worden.",
    "de-DE": "Die Aufgaben werden in einem Kalender durch einfaches Ziehen und Einfügen geplant. Die Planung kann auf Teamebene oder individuell für jeden Mitarbeiter erfolgen. Die Anzeige der verfügbaren Ressourcen (z. B. die Abwesenheit bestimmter Arbeiter oder die Nutzung bestimmter Geräte) ermöglicht eine effiziente Planung."
  },
  "about_planning_workorder_title": {
    "fr-FR": "Bons de travail",
    "en-GB": "Work orders",
    "nl-BE": "Werkbon",
    "de-DE": "Arbeitsauftragsformulare"
  },
  "about_planning_workorder_content": {
    "fr-FR": "Des bons de travail individuels ou groupés peuvent être générés soit depuis une fiche en particulier, soit depuis la liste ou encore depuis le calendrier pour une équipe ou une journée en particulier. Les bons de travail permettent une communication efficace avec les agents et constituent un outil important de retour d’informations des équipes vers leurs superviseurs.",
    "en-GB": "Individual or group work orders can be generated either from a particular work, from the list or from the planning calendar for a particular team or day. Work orders allow effective communication with agents and are an important tool for feedback from teams to their supervisors.",
    "nl-BE": "Individueel of groep werkbon kunnen worden gegenereerd, hetzij van een bepaalde fiche, hetzij van de lijst of uit het agenda voor een team of een bepaalde dag. Werkbon zorgen voor een effectieve communicatie met agenten en zijn een belangrijk instrument voor feedback.",
    "de-DE": "Für ein bestimmtes Team oder einen bestimmten Tag können einzelne oder zusammengefasste Arbeitsauftragsformulare entweder aus einem bestimmten Datenblatt, aus der Liste oder aus dem Kalender generiert werden. Arbeitsauftragsformulare ermöglichen eine effiziente Kommunikation mit den Mitarbeitern und sind ein wichtiges Tool für das Feedback der Teams an ihre Vorgesetzten."
  },
  "about_planning_dashboard_title": {
    "fr-FR": "Tableau de bord",
    "en-GB": "Dashboard",
    "nl-BE": "Dashboard",
    "de-DE": "Dashboard"
  },
  "about_planning_dashboard_content": {
    "fr-FR": "Grâce au tableau de bord en temps réel, vous disposez à tout moment des chiffres clés sur votre activité: taux de résolution moyen, temps d'interventions moyens, répartition par catégories d'interventions, nombre d'interventions par jour, semaine ou mois, etc....",
    "en-GB": "With the real-time dashboard, you have access to all the statistics necessary to analyse and understand the performance of your activity. Average resolution rate, average resolution time and so on...",
    "nl-BE": "Met de dashboard, heeft u toegang aan alle belangrijke informatie om uw aktiviteit goed te kunnen volgen en analyseren.",
    "de-DE": "Dank des Echtzeit-Dashboards haben Sie jederzeit Zugriff auf die wichtigsten Zahlen zu Ihrer Tätigkeit: durchschnittliche Lösungsquote, durchschnittliche Maßnahmendauer, Aufschlüsselung nach Maßnahmenkategorien, Anzahl der Maßnahmen pro Tag, Woche oder Monat, usw...."
  },
  "about_interaction_title": {
    "fr-FR": "Interagir",
    "en-GB": "Analyse",
    "nl-BE": "Analyseren",
    "de-DE": "Interaktion"
  },
  "about_interaction_content": {
    "fr-FR": "Les citoyens de votre commune peuvent directement signaler des soucis à votre service technique et être informé du suivi. Vos interventions peuvent également être communiquées sur un site public prévu à cet effet. Le module citoyens est optionnel.",
    "en-GB": "The citizens of your municipality can directly report concerns to your technical department and be informed of the follow-up. Your interventions can also be communicated on a public site provided for this purpose. The citizens module is optional.",
    "nl-BE": "De burgers van uw stad kan direct bezorgdheid melden aan uw technische dienst en worden geïnformeerd over de monitoring. Uw acties kunnen ook worden meegedeeld op een publieke website. De burgers module is optioneel.",
    "de-DE": "Die Bürgerinnen und Bürger Ihrer Gemeinde können ihre Anliegen direkt an Ihre technische Abteilung melden und werden über die Folgemaßnahmen informiert. Ihre Maßnahmen können auch auf einer dafür vorgesehenen öffentlichen Website mitgeteilt werden. Das Bürgermodul ist optional."
  },
  "about_interaction_citizen_title": {
    "fr-FR": "Signalement par les citoyens",
    "en-GB": "Report by the citizens",
    "nl-BE": "Melding door de burgers",
    "de-DE": "Meldung durch Bürgerinnen und Bürger"
  },
  "about_interaction_citizen_content": {
    "fr-FR": "Avec l'app mobile BetterStreet, les citoyens peuvent facilement effectuer un signalement de qualité au service technique. En effet, chaque signalement se voit accompagné d'une photo, d'une géo-localisation ainsi que d'un commentaire. Pour les citoyens qui ne disposent pas encore de smartphone, un formulaire web permet de signaler depuis le confort de son salon.",
    "en-GB": "With the BetterStreet citizen mobile app, citizens can report easily a problem to their municipality. Indeed, they can do high quality report with a photo, an accurate location and a comment. And for those not yet equiped with a smartphone, they can report via the web form.",
    "nl-BE": "Met de mobiele app BetterStreet kunnen burgers eenvoudig uitvoeren van een kwaliteitsvolle rapportering. Inderdaad, wordt elke melding gezien met een foto, een geo-locatie en een commentaar. Voor burgers die geen smartphone hebben, een webformulier wordt voorgesteld.",
    "de-DE": "Mit der BetterStreet-Mobil-App können Bürgerinnen und Bürger ganz einfach eine Qualitätsmeldung an den technischen Dienst machen. Jede Meldung wird mit einem Foto, einem Geo-Standort und einem Kommentar versehen. Bürgerinnen und Bürger, die kein Smartphone besitzen, können Probleme bequem von zu Hause aus über ein Webformular melden."
  },
  "about_interaction_requestor_title": {
    "fr-FR": "Communication du suivi",
    "en-GB": "Towards the requestor",
    "nl-BE": "Naar de aanvrager",
    "de-DE": "Kommunikation der Folgemaßnahmen"
  },
  "about_interaction_requestor_content": {
    "fr-FR": "Dès l'introduction, le demandeur reçoit un accusé de réception avec un numéro de dossier.\nA chaque modification de l’état d’avancement, le citoyen est tenu au courant de l’évolution de sa demande.",
    "en-GB": "First of all, the requestor receives a delivery receipt with a reference number. Later, when the status of the issue will be changed by its local government, the requestor will be notified per email in real-time.",
    "nl-BE": "De inwoner ontvangt een bevestiging per email met een dossiernummer. Elke statusupdate, wordt aan de inwoner gemeld",
    "de-DE": "Nach der Einreichung erhält der Antragsteller eine Empfangsbestätigung mit einem Aktenzeichen.\nBei jeder Änderung des Status wird der Bürger über den Fortschritt seines Antrags auf dem Laufenden gehalten."
  },
  "about_interaction_population_title": {
    "fr-FR": "Communication & Transparence vis à vis de la population",
    "en-GB": "Towards the population",
    "nl-BE": "Naar alle inwoners",
    "de-DE": "Kommunikation und Transparenz gegenüber der Bevölkerung"
  },
  "about_interaction_population_content": {
    "fr-FR": "L’ensemble des demandes ainsi que leur état d’avancement peut être rendu visible par l’ensemble des citoyens montrant ainsi l’action de la commune. La commune contrôle ce qui est public ou ce qui reste privé. Vous pouvez assigner une tâche à une équipe ou à un agent du service technique, et imprimer un bon de travail qui contient toutes les informations nécessaires à la réalisation de la tâche.",
    "en-GB": "All achievements of the local governments can be made publicly available for viewing. This allows for a greater transparency. The local government keeps of course complete control of what is public or private.",
    "nl-BE": "Een website op naam van uw gemeente wordt gecreëerd op www.betterstreet.org/gemeente. Op die plaats, kan u op een transparante manier over u uitvoeringen communiceren. U blijft altijd in control of wat u toont en wat privé blijft.",
    "de-DE": "Alle Anträge sowie deren Bearbeitungsstand können für alle Bürger sichtbar gemacht werden, was das Handeln der Gemeinde demonstriert. Die Gemeinde kontrolliert, was öffentlich ist oder was privat bleibt. Sie können einem Team oder einem Mitarbeiter des technischen Dienstes eine Aufgabe zuweisen und ein Arbeitsauftragsformular ausdrucken, das alle Informationen enthält, die für die Erledigung der Aufgabe erforderlich sind."
  },
  "about_testimony_title": {
    "fr-FR": "Ce que nos clients disent",
    "en-GB": "What our customers are saying",
    "nl-BE": "Wat onze klanten vertellen",
    "de-DE": "Was unsere Kunden sagen"
  },
  "about_testimony_gsenden_title": {
    "fr-FR": "Ghislain Senden, Elu de Olne (3000 habitants)",
    "en-GB": "Ghislain Senden, Mayor Olne (Liège)",
    "nl-BE": "Ghislain Senden, Burgmeester Olne (Luik)",
    "de-DE": "Ghislain Senden, Bürgermeister von Olne (3.000 Einwohner)"
  },
  "about_testimony_gsenden_content": {
    "fr-FR": "Avec Betterstreet, tout arrive au bon endroit, le suivi est plus rapide, tout est encodé, et celui qui a donné l’information est averti quand le problème est solutionné, ce qui n’était pas le cas auparavant.",
    "en-GB": "With BetterStreet, all requests are coming straight to the right service. Everything is recorded and its speeds up the whole process. The cherry on the cake is that the requestor is informed almost in real-time how we are handling the request.",
    "nl-BE": "Met BetterStreet, komen alle meldingen terecht bij de desbetreffende dienst. De follow-up is sneller, alles is geregisteerd en de aanvrager wordt verwittigd wanneer het probleem opgelost is, hetgeen vroeger niet het geval was.",
    "de-DE": "Mit BetterStreet kommen alle Anträge an der richtigen Stelle an, die Nachverfolgung erfolgt schneller, alle Maßnahmen erhalten eine Kennung und derjenige, der die Meldung aufgegeben hat, wird benachrichtigt, wenn das Problem gelöst wurde, was vorher nicht der Fall war."
  },
  "about_testimony_cdister_title": {
    "fr-FR": "Christophe Dister, Elu de la Hulpe (9000 habitants)",
    "en-GB": "Christophe Dister, Mayor la Hulpe (Wallon)",
    "nl-BE": "Christophe Dister, Burgmeester Ter Hulpen (Waalse Brabant)",
    "de-DE": "Christophe Dister, Bürgermeister von Hulpe (9.000 Einwohner)"
  },
  "about_testimony_cdister_content": {
    "fr-FR": "Auparavant, après un coup de téléphone signalant une incivilité, il fallait envoyer un homme sur place pour vérifier les dires. La photo permettra de voir directement de quel problème il s’agit, où il est situé et d’ensuite envoyer un ouvrier régler la situation.",
    "en-GB": "Before, when for example, an illegal dumping was reported, we had to go there to evaluate what it was all about. Now, thanks to the photo and de localization, we know exactly what the problem is and we can directly plan the intervention to fix the problem.",
    "nl-BE": "Vroeger moest men na een telefonische melding van een sluikstort, ter plaats gaan om te bepalen precies waar het precies over ging. De melding die nu via BetterStreet binnenkomt met foto en exacte locatie vereenvoudigt onze werk en maakt het mogelijk direct naar de planning fase te gaan.",
    "de-DE": "Früher musste nach einem Beschwerdeanruf zunächst jemand die Angaben vor Ort überprüfen. Dank des Fotos kann man nun direkt sehen, um welches Problem es sich handelt und wo es sich befindet und kann dann einen Arbeiter schicken, um das Problem zu beheben."
  },
  "about_testimony_pgodechal_title": {
    "fr-FR": "Pierre Godechal, responsable du service technique à Waremme (16000 habitants)",
    "en-GB": "Pierre Godechal, Chief of Technical service in Waremme (Liège)",
    "nl-BE": "Pierre Godechal, Dienst werken Waremme (Luik)",
    "de-DE": "Pierre Godechal, Leiter der technischen Abteilung in Waremme (16.000 Einwohner)"
  },
  "about_testimony_pgodechal_content": {
    "fr-FR": "Nous cherchions depuis quelque temps une solution de gestion facile à utiliser qui ne nous demanderait pas des mois de mise en œuvre. Avec BetterStreet, nous l’avons trouvé. Gain de temps et efficacité administrative sont les deux grands avantages de cette solution.",
    "en-GB": "For a moment, we had been looking for a user-friendly solution, which would not demand months of painful implementation. We have found it with BetterStreet. After a half-day training, we were up and running with my whole service. No more timewasting and efficiency are the two greatest benefits BetterStreet is bringing to the table.",
    "nl-BE": "We waren al een jaar op zoek naar een gebruiksvriendelijke oplossing die geen 6 maanden implementatie zou vereisen. Onze zoektocht is nu gedaan met BetterStreet. Geen tijdverlies meer en administratieve doelgerichtheid zijn de twee voornaamste troeven van BetterStreet.",
    "de-DE": "Wir waren schon seit einiger Zeit auf der Suche nach einer benutzerfreundlichen Verwaltungslösung, deren Implementierung nicht monatelang dauern würde. Mit BetterStreet haben wir diese gefunden. Zeitersparnis und effiziente Verwaltung sind die beiden großen Vorteile dieser Lösung."
  },
  "account_change_successfull": {
    "fr-FR": "Vos informations ont été mise à jour avec succès",
    "en-GB": "Your information has been updated successfully",
    "nl-BE": "Uw gegevens zijn succesvol bijgewerkt",
    "de-DE": "Ihre Informationen wurden erfolgreich aktualisiert"
  },
  "account_email_change_validation": {
    "fr-FR": "Un email vous a été envoyé sur votre nouvelle adresse email afin de la valider",
    "en-GB": "An email has been sent to your new email address to validate it",
    "nl-BE": "Er is een e-mail verzonden naar uw nieuwe e-mailadres om het te valideren",
    "de-DE": "Ihnen wurde zur Bestätigung eine E-Mail an Ihre neue E-Mail-Adresse geschickt"
  },
  "account_change_email_same_already_present": {
    "fr-FR": "Un compte avec cette adresse email existe déjà",
    "en-GB": "An account with this email address already exists",
    "nl-BE": "Er bestaat al een account met dit e-mailadres",
    "de-DE": "Ein Konto mit dieser E-Mail-Adresse existiert bereits"
  },
  "index_citizen_title": {
    "fr-FR": "Signaler un souci dans l'espace public",
    "en-GB": "Report an issue in the public space",
    "nl-BE": "Melding punt",
    "de-DE": "Ein Anliegen im öffentlichen Raum melden"
  },
  "index_citizen_content": {
    "fr-FR": "Dépôt sauvage, nid de poule, banc cassé? Signaler un souci à sa commune et être informé du suivi n'aura jamais été aussi facile",
    "en-GB": "Illegal dumping, broken light, pothole? Report a problem to its municipality has never been easier.",
    "nl-BE": "Sluikstort, gat in de weg, verlichting, graffiti, enz…Melden is zo gemakkelijk nu met BetterStreet",
    "de-DE": "Illegale Müllentsorgung, Schlaglöcher, kaputte Bänke? Es war noch nie so einfach, ein Problem an seine Gemeinde zu melden und über die Folgemaßnahmen informiert zu werden."
  },
  "index_citizen_button": {
    "fr-FR": "Je suis un citoyen",
    "en-GB": "I am a citizen",
    "nl-BE": "Ik ben a burger",
    "de-DE": "Ich bin eine Bürgerin/ein Bürger"
  },
  "index_commune_title": {
    "fr-FR": "Solution pour services techniques",
    "en-GB": "Solution for Maintenance Team",
    "nl-BE": "Oplossing voor technische dienst",
    "de-DE": "Lösung für technische Dienste"
  },
  "index_commune_content": {
    "fr-FR": "Découvrez la solution de gestion des interventions préventives et curatives la plus conviviale et mobile du marché.",
    "en-GB": "Find out about the most user-friendly and mobile solution for managing interventions",
    "nl-BE": "De collaboratieve oplossing voor leefmilieu en gebouwen beheer die uw leven vereenvoudigt",
    "de-DE": "Entdecken Sie die benutzerfreundlichste und mobilste Lösung für die Verwaltung von vorbeugenden und kurativen Maßnahmen auf dem Markt."
  },
  "index_commune_button": {
    "fr-FR": "Je suis une commune",
    "en-GB": "I am a municipality",
    "nl-BE": "Ik ben een gemeente",
    "de-DE": "Ich bin eine Gemeindevertreterin/ein Gemeindevertreter"
  },
  "home_title_1": {
    "fr-FR": "Nid de poule, banc cassé, dépôt sauvage…",
    "en-GB": "Pothole, broken bench, illegal dumping, etc…",
    "nl-BE": "Gat in de weg, gebroken stadsmeubilair, sluikstort…",
    "de-DE": "Schlaglöcher, kaputte Bänke, illegale Müllentsorgung..."
  },
  "home_title_2": {
    "fr-FR": "Signalez un souci à votre commune en moins de 30 secondes via BetterStreet",
    "en-GB": "Report an issue in less than 30 seconds via BetterStreet",
    "nl-BE": "Meld een probleem in minder dan 30 seconden via BetterStreet",
    "de-DE": "Melden Sie Ihrer Gemeinde ein Anliegen in weniger als 30 Sekunden über BetterStreet"
  },
  "home_step_1": {
    "fr-FR": "Dépôt sauvage, nids de poule, éclairage public défectueux, etc…",
    "en-GB": "Illegal dumping, pothole, defect light, etc…",
    "nl-BE": "Sluikstort, gat in de weg, verlichting, graffiti, enz…",
    "de-DE": "Illegale Müllentsorgung, Schlaglöcher, defekte Straßenbeleuchtung etc."
  },
  "home_step_2": {
    "fr-FR": "Signalez le problème via notre application mobile ou formulaire web",
    "en-GB": "Report the problem via our mobile app or our web form",
    "nl-BE": "Meldt het probleem via onze mobiele app of ons web formulier",
    "de-DE": "Melden Sie das Problem über unsere mobile App oder das Webformular"
  },
  "home_step_3": {
    "fr-FR": "Votre commune est notifiée et peut prendre action",
    "en-GB": "Your local government is informed and can take action",
    "nl-BE": "Uw gemeente wordt verwittigd en kan actie ondernemen",
    "de-DE": "Ihre Gemeinde wird benachrichtigt und kann Maßnahmen ergreifen"
  },
  "home_step_4": {
    "fr-FR": "Soyez informé de l'évolution de votre signalement!",
    "en-GB": "Done!",
    "nl-BE": "Gedaan!",
    "de-DE": "Lassen Sie sich über den Fortschritt Ihrer Meldung informieren!"
  },
  "ABOUT": {
    "fr-FR": "A PROPOS",
    "en-GB": "ABOUT US",
    "nl-BE": "OVER ONS",
    "de-DE": "ÜBER"
  },
  "NEW_ISSUE": {
    "fr-FR": "NOUVEAU SIGNALEMENT",
    "en-GB": "NEW REQUEST",
    "nl-BE": "NIEUWE MELDING",
    "de-DE": "NEUE MELDUNG"
  },
  "LOGIN": {
    "fr-FR": "SE CONNECTER",
    "en-GB": "LOGIN",
    "nl-BE": "INLOGGEN",
    "de-DE": "ANMELDEN"
  },
  "login": {
    "fr-FR": "Se connecter",
    "en-GB": "Login",
    "nl-BE": "Inloggen",
    "de-DE": "Anmelden"
  },
  "continue_as": {
    "fr-FR": "Continuer en tant que",
    "en-GB": "",
    "nl-BE": "",
    "de-DE": "Fortsetzen als"
  },
  "connect_with_facebook": {
    "fr-FR": "Se connecter avec facebook",
    "en-GB": "Log in with Facebook",
    "nl-BE": "Aanmelden met Facebook",
    "de-DE": "Mit Facebook anmelden"
  },
  "login_to_use": {
    "fr-FR": "Veuillez vous connecter afin d'utiliser cette fonctionnalité...",
    "en-GB": "Please sign in to use this feature ...",
    "nl-BE": "Gelieve in te loggen om deze functionaliteit te gebruiken...",
    "de-DE": "Bitte melden Sie sich an, um diese Funktion nutzen zu können..."
  },
  "login_with_facebook": {
    "fr-FR": "S'identifier au moyen de votre compte Facebook",
    "en-GB": "Login using your Facebook account",
    "nl-BE": "Inloggen met uw Facebook account",
    "de-DE": "Mit Facebook-Konto anmelden"
  },
  "login_facebook_be_sure": {
    "fr-FR": "Nous récupérons uniquement votre adresse email et ne publierons jamais rien sur votre mur.",
    "en-GB": "We only recuperate your email address. We will never post anything on your facebook wall.",
    "nl-BE": "We recupereren alleen maar uw email adres. We zullen nooit iets op uw wall gaan posten.",
    "de-DE": "Wir erheben nur Ihre E-Mail-Adresse und werden niemals etwas in Ihrer Chronik veröffentlichen."
  },
  "login_with_bs": {
    "fr-FR": "S'identifier au moyen de votre compte utilisateur BetterStreet",
    "en-GB": "Login with your BetterStreet user account",
    "nl-BE": "Inloggen met uw BetterStreet gebruikersaccount",
    "de-DE": "Sich mit Ihrem BetterStreet-Benutzerkonto anmelden"
  },
  "email": {
    "fr-FR": "Email",
    "en-GB": "Email",
    "nl-BE": "Email",
    "de-DE": "E-Mail"
  },
  "email_already_used": {
    "fr-FR": "Un compte avec cette adresse email existe déjà",
    "en-GB": "An account with this email address already exists",
    "nl-BE": "Er bestaat al een account met dit e-mailadres",
    "de-DE": "Ein Konto mit dieser E-Mail-Adresse existiert bereits"
  },
  "password": {
    "fr-FR": "Mot de passe",
    "en-GB": "Password",
    "nl-BE": "Wachtwoord",
    "de-DE": "Passwort"
  },
  "password_verification": {
    "fr-FR": "Mot de passe (vérification)",
    "en-GB": "Password (verification)",
    "nl-BE": "Wachtwoord (verificatie)",
    "de-DE": "Passwort (Überprüfung)"
  },
  "forgot_password": {
    "fr-FR": "Mot de passe oublié ?",
    "en-GB": "Forgot your password ?",
    "nl-BE": "Wachtwoord vergeten ?",
    "de-DE": "Passwort vergessen ?"
  },
  "create_free_account": {
    "fr-FR": "Créer un nouveau compte gratuit",
    "en-GB": "Create a new free account",
    "nl-BE": "Een nieuwe gratis gebruiker creeren",
    "de-DE": "Ein neues kostenloses Konto erstellen"
  },
  "admin_login": {
    "fr-FR": "Connexion pour les utilisateurs d’institutions publiques",
    "en-GB": "Login for users of public institutions",
    "nl-BE": "Login voor de gebruikers van openbare instellingen",
    "de-DE": "Login für Nutzer öffentlicher Einrichtungen"
  },
  "reinitialize_password": {
    "fr-FR": "Réinitialiser votre mot de passe",
    "en-GB": "Reset your password",
    "nl-BE": "Uw wachtwoord resetten",
    "de-DE": "Passwort zurücksetzen"
  },
  "new_user_account": {
    "fr-FR": "Nouveau compte utilisateur",
    "en-GB": "User registration",
    "nl-BE": "Registratie",
    "de-DE": "Neues Benutzerkonto"
  },
  "new_user_account_validate_mail": {
    "fr-FR": "Compte utilisateur créé, mais encore à valider. Un email de validation vous a été envoyé.",
    "en-GB": "User account created, but still to be validated. A validation email has been sent to you.",
    "nl-BE": "Gebruikersaccount aangemaakt, maar nog te valideren. Een validatie e-mail werd naar U verstuurd.",
    "de-DE": "Das Benutzerkonto wurde angelegt, muss aber noch bestätigt werden. Es wurde eine Bestätigungs-E-Mail an Sie gesendet."
  },
  "user_forgot_password_mail_sended": {
    "fr-FR": "Un email vous a été envoyé pour réinitialiser votre mot de passe.",
    "en-GB": "An email has been sent to you in order to reset your password",
    "nl-BE": "Een e-mail werd naar u verzonden om uw wachtwoord te kunnen resetten.",
    "de-DE": "Ihnen wurde eine E-Mail geschickt, um Ihr Passwort zurückzusetzen."
  },
  "create_account": {
    "fr-FR": "Créer le compte",
    "en-GB": "Create account",
    "nl-BE": "Registreren",
    "de-DE": "Konto erstellen"
  },
  "firstname": {
    "fr-FR": "Prénom",
    "en-GB": "First name",
    "nl-BE": "Voornaam",
    "de-DE": "Vorname"
  },
  "lastname": {
    "fr-FR": "Nom",
    "en-GB": "Last name",
    "nl-BE": "Naam",
    "de-DE": "Name"
  },
  "condition_begin": {
    "fr-FR": "J'ai lu et accepte les ",
    "en-GB": "I have read and accept Betterstreet's ",
    "nl-BE": "Ik heb de ",
    "de-DE": "Ich habe "
  },
  "condition_link": {
    "fr-FR": "conditions de services",
    "en-GB": "terms and conditions",
    "nl-BE": "algemene voorwaarden",
    "de-DE": "die Dienstleistungsbedingungen"
  },
  "condition_end": {
    "fr-FR": " de BetterStreet.",
    "en-GB": " .",
    "nl-BE": " van BetterStreet gelezen en geaccepteerd.",
    "de-DE": " von BetterStreet gelesen und akzeptiere diese."
  },
  "accept_condition": {
    "fr-FR": "Merci d'accepter les conditions afin de créer votre compte",
    "en-GB": "You need to indicate that you accept the terms and conditions to create your account.",
    "nl-BE": "U dient de voorwaarden te accepteren om uw account te creeren.",
    "de-DE": "Bitte akzeptieren Sie die Bedingungen, um Ihr Konto zu erstellen"
  },
  "not_corresponding_password": {
    "fr-FR": "Le mot de passe de validation ne correspond pas au mot de passe que vous avez saisi",
    "en-GB": "Incorrect verification password",
    "nl-BE": "Validatie wachtwoord onjuist",
    "de-DE": "Das Passwort zur Überprüfung stimmt nicht mit dem Passwort überein, das Sie eingegeben haben."
  },
  "logout": {
    "fr-FR": "Déconnexion",
    "en-GB": "Log out",
    "nl-BE": "Log out",
    "de-DE": "Abmelden"
  },
  "my_profile": {
    "fr-FR": "Mon profil",
    "en-GB": "My profile",
    "nl-BE": "Mijn Profiel",
    "de-DE": "Mein Profil"
  },
  "my_issues": {
    "fr-FR": "Mes signalements",
    "en-GB": "My reports",
    "nl-BE": "Mijn meldingen",
    "de-DE": "Meine Meldungen"
  },
  "followed_issues": {
    "fr-FR": "Signalements suivis",
    "en-GB": "Followed reports",
    "nl-BE": "Rapporten gevolgd",
    "de-DE": "Berichte folgten"
  },
  "only_mine": {
    "fr-FR": "Seulement les miens",
    "en-GB": "My reports",
    "nl-BE": "Mijn meldingen",
    "de-DE": "Nur meine Meldungen"
  },
  "already": {
    "fr-FR": "Déjà",
    "en-GB": "Already",
    "nl-BE": "Al",
    "de-DE": "Bereits"
  },
  "issues": {
    "fr-FR": "signalements",
    "en-GB": "reports",
    "nl-BE": "meldingen",
    "de-DE": "Meldungen"
  },
  "in_country_whose": {
    "fr-FR": "en Belgique, France et Luxembourg, dont",
    "en-GB": "in Belgium, France and Luxembourg of which",
    "nl-BE": "in België, Frankrijk en Luxemburg, waarvan ",
    "de-DE": "in Belgien, Frankreich und Luxemburg, davon wurden"
  },
  "resolveds": {
    "fr-FR": "résolus",
    "en-GB": "resolved",
    "nl-BE": "opgelost",
    "de-DE": "gelöst"
  },
  "issue_create": {
    "fr-FR": "Introduire un nouveau signalement",
    "en-GB": "Introduction of a new request",
    "nl-BE": "Een nieuwe melding toevoegen",
    "de-DE": "Neue Meldung eingeben"
  },
  "optional": {
    "fr-FR": "Optionnel",
    "en-GB": "Optional",
    "nl-BE": "Optioneel",
    "de-DE": "Optional"
  },
  "localize": {
    "fr-FR": "Localisez",
    "en-GB": "Locate",
    "nl-BE": "Lokaliseer",
    "de-DE": "Ort angeben"
  },
  "localize_write_or_map": {
    "fr-FR": "Indiquez l’adresse ci-dessous OU déplacez le pointeur sur la carte",
    "en-GB": "Indicate the address hereunder OR move the pointer on the map",
    "nl-BE": "Voer het adres hieronder of verplaatst de aanwijzer op de kaart",
    "de-DE": "Geben Sie die Adresse unten an ODER klicken Sie auf den Ort auf der Karte"
  },
  "where": {
    "fr-FR": "Où",
    "en-GB": "Where",
    "nl-BE": "Waar",
    "de-DE": "Wo"
  },
  "import_file": {
    "fr-FR": "Importez votre photo",
    "en-GB": "Upload your photo",
    "nl-BE": "Laad uw foto op",
    "de-DE": "Importieren Sie Ihr Foto"
  },
  "issue_form_size_max": {
    "fr-FR": "5 photos maximum, maximum 5 MB",
    "en-GB": "5 photos maximum, maximum 5 MB",
    "nl-BE": "Maximaal 5 foto's, maximaal 5 MB",
    "de-DE": "5 Fotos maximal, maximal 5 MB"
  },
  "choose_category": {
    "fr-FR": "Choisissez une catégorie",
    "en-GB": "Select a category",
    "nl-BE": "Selecteer een categorïe",
    "de-DE": "Wählen Sie eine Kategorie"
  },
  "description": {
    "fr-FR": "Votre commentaire",
    "en-GB": "Your comment",
    "nl-BE": "Uw commentaar",
    "de-DE": "Ihr Kommentar"
  },
  "description_max_size": {
    "fr-FR": "Maximum 2000 caractères (Ne pas saisir d'information personnelle)",
    "en-GB": "Max. 2000 characters (Do not enter personal information)",
    "nl-BE": "Max. 2000 karakters (Voer geen persoonlijke gegevens in)",
    "de-DE": "Maximal 2000 Zeichen (Geben Sie keine persönlichen Informationen ein)"
  },
  "to_be_notified": {
    "fr-FR": "Être notifié",
    "en-GB": "Get notified",
    "nl-BE": "Hou me op de hoogte",
    "de-DE": "Benachrichtigt werden"
  },
  "receive_mail_on_update": {
    "fr-FR": "Vous recevrez un email quand le problème posté sera modifié",
    "en-GB": "Receive an email when your posted issue status changes",
    "nl-BE": "Email verwittig in geval van status wijziging",
    "de-DE": "Sie erhalten eine E-Mail, wenn das gemeldete Problem einen anderen Status erhält."
  },
  "renew_password": {
    "fr-FR": "Modifier mon mot de passe",
    "en-GB": "Change my password",
    "nl-BE": "Verander mijn wachtwoord",
    "de-DE": "Ändere mein Passwort"
  },
  "receive_rgpd": {
    "fr-FR": "Demander mes données RGPD",
    "en-GB": "Ask my GPRD data",
    "nl-BE": "Vraag mijn AVG-gegevens op",
    "de-DE": "Meine DSGVO-Daten anfordern"
  },
  "cancel_my_account": {
    "fr-FR": "Supprimer mon compte",
    "en-GB": "Cancel my account",
    "nl-BE": "Mijn account verwijderen",
    "de-DE": "Mein Konto löschen"
  },
  "personal_data_title": {
    "fr-FR": "Vos données RGPD",
    "en-GB": "Your GDPR data",
    "nl-BE": "Uw AVG-gegevens",
    "de-DE": "Ihre DSGVO-Daten"
  },
  "personal_data_nature": {
    "fr-FR": "Nature des données personnelles en notre possession",
    "en-GB": "Nature of personal data in our possession",
    "nl-BE": "Aard van de persoonsgegevens in ons bezit",
    "de-DE": "Art der personenbezogenen Daten in unserem Besitz"
  },
  "personal_data_email": {
    "fr-FR": "Adresse électronique: %email%",
    "en-GB": "Email address: %email%",
    "nl-BE": "E-mailadres: %email%",
    "de-DE": "E-Mail-Adresse: %email%"
  },
  "personal_data_firstname": {
    "fr-FR": "Prénom: %firstname%",
    "en-GB": "First name: %firstname%",
    "nl-BE": "Voornaam: %firstname%",
    "de-DE": "Vorname: %firstname%"
  },
  "personal_data_lastname": {
    "fr-FR": "Nom: %lastname%",
    "en-GB": "Name: %lastname%",
    "nl-BE": "Naam: %lastname%",
    "de-DE": "Name: %lastname%"
  },
  "personal_data_delete": {
    "fr-FR": "A votre demande, ces données peuvent être supprimées de notre base de données. Les signalements saisis par le biais de votre compte Betterstreet seront anonymisés",
    "en-GB": "At your request, this data can be deleted from our database. Reports entered through your Betterstreet account will be anonymized",
    "nl-BE": "Op uw verzoek kunnen deze gegevens uit onze database worden verwijderd. Rapporten die via uw Betterstreet-account worden ingevoerd, worden geanonimiseerd.",
    "de-DE": "Auf Ihren Antrag hin können diese Daten aus unserer Datenbank gelöscht werden. Die über Ihr BetterStreet-Konto eingegebenen Meldungen werden anonymisiert."
  },
  "delete_account_title": {
    "fr-FR": "Confirmation de suppression de compte",
    "en-GB": "Confirmation of account deletion",
    "nl-BE": "Bevestiging de suppressie de compte",
    "de-DE": "Bestätigung der Kontolöschung"
  },
  "delete_account_confirmation": {
    "fr-FR": "Êtes vous certains de vouloir supprimer votre compte",
    "en-GB": "Are you sure you want to delete your account",
    "nl-BE": "Weet u zeker dat u uw account wilt verwijderen",
    "de-DE": "Sind Sie sicher, dass Sie Ihr Konto löschen wollen?"
  },
  "delete_account_irreversible": {
    "fr-FR": "Cette opération est irréversible",
    "en-GB": "This operation is irreversible",
    "nl-BE": "Deze operatie is onomkeerbaar",
    "de-DE": "Dieser Vorgang ist unumkehrbar"
  },
  "delete_account_cancel": {
    "fr-FR": "Annuler",
    "en-GB": "Cancel",
    "nl-BE": "Annuleer",
    "de-DE": "Abbrechen"
  },
  "delete_account_confirm": {
    "fr-FR": "Confirmer",
    "en-GB": "Confirm",
    "nl-BE": "Bevestigen",
    "de-DE": "Bestätigen"
  },
  "follow": {
    "fr-FR": "Suivre",
    "en-GB": "Follow",
    "nl-BE": "Volgen",
    "de-DE": "Verfolgen"
  },
  "unfollow": {
    "fr-FR": "Ne plus suivre",
    "en-GB": "No longer follow",
    "nl-BE": "Niet langer volgen",
    "de-DE": "Nicht mehr verfolgen"
  },
  "write_comment": {
    "fr-FR": "Écrire un commentaire",
    "en-GB": "Write a comment",
    "nl-BE": "Schrijf een reactie",
    "de-DE": "Einen Kommentar schreiben"
  },
  "issue_form_citizen_issue_forbidden": {
    "fr-FR": "Il n’est pas possible d’effectuer un signalement sur cette commune. Soit elle n’est pas cliente de notre solution, soit elle a décidé de ne pas ouvrir cette fonction aux citoyens. Merci de vous rapprocher de la mairie concernée pour plus d’informations.",
    "en-GB": "Citizen reporting not allowed by the community",
    "nl-BE": "Burgermelding niet geautoriseerd door de gemeente",
    "de-DE": "Bürgermeldung, die nicht von der Gemeinde genehmigt wurde"
  },
  "issue_form_citizen_tenant_unknow" : {
    "fr-FR": "Nous n'avons pas réussi à trouver la commune concernée à partir de la position géographique renseignée. Merci d'entrer une nouvelle position.",
    "en-GB": "We have not been able to find the municipality concerned from the geographical position provided. Please enter a new location.",
    "nl-BE": "We hebben de betreffende gemeente niet kunnen vinden op basis van de opgegeven geografische positie. Voer een nieuwe functie in.",
    "de-DE": "Wir konnten die betreffende Gemeinde anhand der angegebenen geografischen Position nicht finden. Bitte geben Sie eine neue Position ein."
  },
  "address_unknown": {
    "fr-FR": "Adresse inconnue",
    "en-GB": "Address unknown",
    "nl-BE": "Adres onbekend",
    "de-DE": "Adresse unbekannt"
  },
  "select_city_filter_placeholder": {
    "fr-FR": "Nom de la commune",
    "en-GB": "Name of the municipality",
    "nl-BE": "Naam van de gemeente",
    "de-DE": "Name der Gemeinde"
  },
  "issue_creation_successfull_on_not_client_tenant": {
    "fr-FR": "La commune sélectionnée n'utilise pas BetterStreet pour la gestion de ses interventions. Nous mettons tout en œuvre pour transmettre votre signalement. Vous serez informé prochainement du suivi de votre demande.",
    "en-GB": "The selected municipality does not use BetterStreet to manage its interventions. We will do everything we can to pass on your report. You will be informed shortly of the progress of your request.",
    "nl-BE": "Die ausgewählte Gemeinde nutzt BetterStreet nicht zur Verwaltung ihrer Interventionen. Wir werden unser Möglichstes tun, um Ihren Bericht weiterzugeben. Sie werden in Kürze über den Fortschritt Ihrer Anfrage informiert.",
    "de-DE": "De geselecteerde gemeente gebruikt BetterStreet niet om haar interventies te beheren. Wij zullen er alles aan doen om uw melding door te geven. U wordt binnenkort geïnformeerd over de voortgang van uw aanvraag."
  },
  "issue_creation_successfull_on_client_tenant": {
    "fr-FR": "La commune de %tenantName% a bien reçu votre signalement %bsId%",
    "en-GB": "The municipality of %tenantName% has received your report %bsId%",
    "nl-BE": "De gemeente %tenantName% heeft uw melding %bsId% ontvangen",
    "de-DE": "Die Gemeinde %tenantName% hat Ihre Meldung erhalten %bsId%"
  },
  "issue_creation_successfull_on_client_tenant_redirect": {
      "fr-FR": "Votre signalement %bsId% à été redirigé vers la catégorie %categoryName% de la commune de %tenantName%",
      "en-GB": "Your report %bsId% has been redirected to the %categoryName% category in the %tenantName% municipality",//traduction chat gpt
      "de-DE": "Ihr Bericht %bsId% wurde in die Kategorie %categoryName% der Gemeinde %tenantName% weitergeleitet",//traduction chat gpt
      "nl-BE": "Uw melding %bsId% is doorgestuurd naar de categorie %categoryName% in de gemeente %tenantName%"//traduction chat gpt
  },
  "issue_creation_successfull_on_client_tenant_redirect_private": {
      "fr-FR": "Votre signalement %bsId% à été redirigé vers la commune de %tenantName% et identifié comme privée. Vous pouvez vous rapprocher de votre commune pour de plus amples informations.",
      "en-GB": "Your report %bsId% has been forwarded to the municipality of %tenantName% and marked as private. You can contact your municipality for more information.",//traduction chat gpt
      "de-DE": "Ihre Meldung %bsId% wurde an die Gemeinde %tenantName% weitergeleitet und als privat gekennzeichnet. Für weitere Informationen können Sie sich an Ihre Gemeinde wenden",//traduction chat gpt
      "nl-BE": "Uw melding %bsId% is doorgestuurd naar de gemeente %tenantName% en als privé gemarkeerd. U kunt contact opnemen met uw gemeente voor meer informatie"//traduction chat gpt
  },
  "my_profile_others": {
    "fr-FR": "Autres actions",
    "en-GB": "Other actions", //Traduction Copilot
    "nl-BE": "Andere acties", //Traduction Copilot
    "de-DE": "Andere Aktionen" //Traduction Copilot
  },
  "subscription_updated": {
    "fr-FR": "Vos inscriptions aux notifications ont bien été mises à jour",
    "en-GB": "Your subscriptions to notifications have been updated", //Traduction Copilot
    "nl-BE": "Uw inschrijvingen voor notificaties zijn bijgewerkt", //Traduction Copilot
    "de-DE": "Ihre Anmeldung für Benachrichtigungen wurde erfolgreich aktualisiert" //Traduction Copilot
  },
  "subscription_title": {
    "fr-FR": "Inscription aux notifications",
    "en-GB": "Subscription to notifications", //Traduction Copilot
    "nl-BE": "Inschrijving voor notificaties", //Traduction Copilot
    "de-DE": "Anmeldung für Benachrichtigungen" //Traduction Copilot
  },
  "subscription_onIssueCreated": {
    "fr-FR": "Lorsque vous créez un signalement",
    "en-GB": "When you create a report", //Traduction Copilot
    "nl-BE": "Wanneer u een melding maakt", //Traduction Copilot
    "de-DE": "Wenn Sie eine Meldung erstellen" //Traduction Copilot
  },
  "subscription_onIssueStateUpdated": {
    "fr-FR": "Lorsque le statut de vos signalements est mis à jour",
    "en-GB": "When the status of your reports is updated", //Traduction Copilot
    "nl-BE": "Wanneer de status van uw meldingen wordt bijgewerkt", //Traduction Copilot
    "de-DE": "Wenn der Status Ihrer Meldungen aktualisiert wird" //Traduction Copilot
  },
  "subscription_onCitizenComment": {
    "fr-FR": "Lorsqu'un citoyen commente un signalement que vous suivez",
    "en-GB": "When a citizen comments on a report you follow", 
    "nl-BE": "Wanneer een burger commentaar geeft op een rapport dat u volgt", //Traduction Google
    "de-DE": "Wenn ein Bürger einen Kommentar zu einem Bericht abgibt, dem Sie folgen" //Traduction Google
  },
  "subscription_onAdminComment": {
    "fr-FR": "Lorsqu'un agent de la commune commente un de vos signalements",
    "en-GB": "When a municipal agent comments on one of your reports", //Traduction Copilot
    "nl-BE": "Wanneer een ambtenaar van de gemeente een van uw meldingen becommentarieert", //Traduction Copilot
    "de-DE": "Wenn ein Gemeindebeamter eine Ihrer Meldungen kommentiert" //Traduction Copilot
  },
  "src_applications_view_session_validateUpdateGpuButton": {
    "fr-FR": "Confirmer",
    "en-GB": "Confirm",
    "nl-BE": "Bevestigen", //traduction google translate
    "de-DE": "Bestätigen" //Traduction google translate
  },
  "src_applications_view_session_validateUpdateGpuCheckbox": {
    "fr-FR": "J'ai lu et j'accepte les conditions générales",
    "en-GB": "I have read and accept the general conditions",//traduction google translate 
    "nl-BE": "Ik heb de algemene voorwaarden gelezen en accepteer deze", //traduction google translate
    "de-DE": "Ich habe die Allgemeinen Geschäftsbedingungen gelesen und akzeptiere sie" //Traduction google translate
  },
  "src_applications_view_session_Title": {
    "fr-FR": "Nouvelles conditions générales d'utilisation",
    "en-GB": "New Terms and Conditions of Use",//traduction chat gpt
    "nl-BE": "Nieuwe Algemene Gebruiksvoorwaarden", //traduction chat gpt
    "de-DE": " Neue Allgemeine Nutzungsbedingungen" //Traduction chat gpt
  }
};
