export default {
  log: (...args) => {
    if(window.process && window.process.env && window.process.env === 'development'){
      console.log(...args);
    }
  },
  info:  (...args) => {
    if(window.process && window.process.env && window.process.env === 'development'){
      console.info(...args);
    }
  },
  warn:  (...args) => {
    if(window.process && window.process.env && window.process.env === 'development'){
      console.warn(...args);
    }
  },
  error: (...args) => {
    if(window.process && window.process.env && window.process.env === 'development'){
      console.error(...args);
    }
  },
  debug: (...args) => {
    if(window.process && window.process.env && window.process.env === 'development'){
      console.debug(...args);
    }
  }
}