import React             from "react";
import { Link }          from "@cFeatures/router";
import YouTube           from "react-youtube";
import MobileStoreButton from './mobileLink';
import T                 from "@cBehaviour/i18n";
import Scrollbar         from "@cComponents/scrollBar";
import Input             from "@cComponents/old/input";
import Social            from "@entities/social";
import Application       from "@uBehaviour/application";
import Data              from "@uBehaviour/data";
import Filter            from "@cComponents/filter"

import "./citizen.css";

const getCityCode = (tenant) => tenant.informations?.postalCode.join(", ");

class Citizen extends React.Component{
  _getQuery = (value) => {
    const postalCodeRegex = `^${value}`;
    const or = [
      { [this.props.i18n.queryProperty("name")]: { '$regex': `^${value}`, '$options' : 'i' }},
      {'cityCode': value },                          
    ];

    if(value.length > 2){
      or.push({ 'informations.postalCode': { '$regex': postalCodeRegex } })
    }
    return({ $or: or });
  }


  render(){
    return (
      <Scrollbar>
        <div className="bs-citizen">
          <div className="bs-citizen-search-commune">
            <div>
              <h2>
                <T>home_title_1</T>
                <br />
                <T>home_title_2</T>
              </h2>
              <div className="bs-select-city">
                <Filter.Generic name="town" buildQuery={value => ( { "tenant": value })}>
                {(value, set, clear) => (
                  <Input.Autocomplete.Model noError={ true } model={ "Tenant" } name="tenant" 
                    querying={ this._getQuery }
                    sort={{[this.props.i18n.queryProperty("name")]: 1}}
                    labelize={(t) => `${this.props.i18n.translate(t.name)} - ${t.country.toUpperCase()} - ${getCityCode(t)}` } 
                    onChange={ (component) => {
                      set(component.value);
                    }}
                    value={value}>
                    {this.props.i18n.translate("select_city_filter_placeholder")}
                    </Input.Autocomplete.Model>
                  )}
                  </Filter.Generic>
                  <Link to="/issues" fromRoot><button>GO</button></Link>
                </div>
            </div>
          </div>          
          <div class="bs-citizen-steps">
            <div class="bs-citizen-steps-content">
              {
                [1, 2, 3, 4].map((line, idx) => (
                  <div className={ "bs-citizen-step bs-citizen-step-" + line } >
                      <div><T>{"home_step_" + line}</T></div>
                  </div>
                ))
              }
            </div>
          </div>
          <div className="bs-promo">
            <div className="bs-promo-left">
              <YouTube videoId={"S4zHVrq7yQo"} opts={{ height: 360, width: 760, playerVars: { autoplay: true, loop: 1, mute: 1 }}}/>
            </div>
            <div className="bs-promo-right">
              <Data.Async async={() => this.props.api.service("issues", "countAll").execute() }>
                {issues => (
                  <span className="bs-promo-issues"><T>already</T> <Link to="/issues"><span className="bs-promo-issues-total">{ issues.total } <T>issues</T></span></Link> <T>in_country_whose</T> <span className="bs-promo-issues-resolved">{issues.resolved} <T>resolveds</T></span></span>
                )}
              </Data.Async>
              <div className="bs-promo-mobile-app">
                <MobileStoreButton
                  store="ios"
                  url="https://itunes.apple.com/FR/app/betterstreet/id603534661"
                  language={ this.props.i18n.currentLanguage }
                  proportion={ 4/3 }
                  linkProps={{ title: 'iOS Store Button' }}
                />
                <MobileStoreButton
                  store="android"
                  url="https://play.google.com/store/apps/details?id=org.betterstreet"
                  language={ this.props.i18n.currentLanguage }
                  proportion={ 4/3 }
                  linkProps={{ title: 'Android Store Button' }}
                />
              </div>
            </div>
          </div>
          < Social />
        </div>
      </Scrollbar>
    );
  }
}

export default Application.Service.forward(["i18n", "microstore", "api"], Citizen);