import { Helper } from "@uLib/application";

export default class IssueHelper extends Helper {
  constructor(){
    super("issue", ["api", "message", "repository"]);
  }
  
  follow(issueId) {
    this.application.getService("api").service("issues", "follow").execute(issueId.toString()).then((issue) => {
      this.application.getService("repository").update("Issue", "update", [issue]);
      this.application.getService("message").send("info", this.application.getService("i18n").translate('issue_followed'));
    });
  }

  unfollow(issueId) {
    this.application.getService("api").service("issues", "unfollow").execute(issueId.toString()).then((issue) => {
      this.application.getService("repository").update("Issue", "update", [issue])
      this.application.getService("message").send("info", this.application.getService("i18n").translate('issue_unfollowed'));
    });
  }

  sendPublicComment(issueId, comment) {
    return this.application.getService("api").service("issues", "createPublicComment").execute(issueId.toString(), comment)
      .then((comment) => {
        this.application.getService("message").send("info", this.application.getService("i18n").translate('message_sent'));
        return comment;
      });
  }

  addFile(issueId, file){
    return this.application.getService("api").service("issues", "addFile").execute(issueId, file)
        .then((issue) => this.application.getService("repository").update("Issue", "update", [issue]));
  }

  removeFile(issueId, file) {
    return this.application.getService("api").service("issues", "removeFile").execute(issueId, file)
        .then((issue) => this.application.getService("repository").update("Issue", "update", [issue]));
  }

  nears(position, query, offset, limit) {
    return this.application.getService("api").service("issues", "nears").execute(position, query, limit, offset);
  }
  
}