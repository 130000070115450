import Validator  from 'fastest-validator';
import _          from 'lodash';
import Logger from './logger';

const errorsCode = {
  "required":       "required_field",
  "email":          "incorrect_email_format",
  "emailEmpty":     "empty_field_not_allowed",
  "stringPattern":  "incorrect_field_pattern",
  "stringEmpty":    "empty_field_not_allowed",
  "stringLength":   "validator_error_stringLength",
  "arrayEmpty":     "empty_field_not_allowed",
  "arrayLength":    "empty_field_not_allowed",
  "stringEnum":     "validator_error_stringEnum",
  "stringMin":      "string_min",
  "array":          "required_field", 
  "arrayMin":       "required_field",
  "number":         "validator_number_field_value_required",
  "numberPositive": "validator_number_field_positive_value_error",
  "date":           "validator_date_unknow"
};


const addValidatorDiscriminator = (validator) => {
  validator.add("discriminator", function({ schema, messages }, path, context) {
    if(!schema.rules){
      throw new Error("discriminator rule must have rules attribute");
    }
    if (!context.discRules) {
      context.discRules = {};
    }
    context.discRules[path] = schema.rules.map(({ test, rule }) => {
      return {
        test,
        rule: this.getRuleFromSchema(rule)
      };
    });
    const fn = [];
    if(schema.nullable){
      fn.push(`  if(value === null){
        return null;
      }`);
    }
    
    fn.push("let _errors = [];");

    for(let i = 0; i < context.discRules[path].length; i++) {
      fn.push(`
        if(context.discRules["${path}"][${i}].test(value)){
          ${this.compileRule(context.discRules[path][i].rule, context, path, `var tmpVal = ${context.async ? "await " : ""}context.fn[%%INDEX%%](value, field, parent, _errors, context);`, "tmpVal")}
        }
      `);
    }

    fn.push(`
      if(_errors.length){
        Array.prototype.push.apply(errors, _errors);
      }
    `);

    return {
      source: fn.join("\n")
    };
});
}

const addValidator = (validator) => {
  addValidatorDiscriminator(validator);
}

const getI18nKey = (errorType) => {
  if (errorsCode[errorType] !== undefined) {
    if(!errorsCode[errorType]){
      Logger.warn(new Error(`L'erreur "${errorType}" a été détecté par fastestValidator, il peut s'agir d'un problème de conception de formulaire (mauvais choix d'input)`));
    }
    return errorsCode[errorType];
  } else {
    throw new Error(`unknow error type : ${errorType}`);
  }
}

export const buildValidator = (rules) => {
  const validator = rules.map(({ premise, schema }) => {
    const v = new Validator();
    addValidator(v);
    return {
      premise,
      rule: v.compile(schema)
    };
  });
  
  return {
    rules: [(value) => {
      const find = validator.find(({ premise }) => premise(value));
      if(!find){
        throw new Error("unknown validator");
      }
      return prepareErrors(find.rule(value));
    }]
  };
};

const prepareErrors = (errors) => {
  if(!Array.isArray(errors)){
    return [];
  }
  let lastError = null;
  return errors.map(error => {
    return {
      path: error.field,
      error: getI18nKey(error.type),
      original: error
    }
  })
  .filter(e => !!e.error)
  .sort((e1, e2) => {
    const comp1 = e1.path.localeCompare(e2.path);
    if(comp1 !== 0){
      return comp1;
    }
    return e1.error.localeCompare(e2.error);
  })
  .filter(e => {
    if(!lastError){
      lastError = e;
      return true;
    }
    if(e.error !== lastError.error || e.path !== lastError.path){
      lastError = e;
      return true;
    }
    return false;
  });
};

const validate = async (validator, value) => {
  let errors = [];
  if (validator.schema) {
    const v = new Validator();
    addValidator(v);
    const schemaValidated = v.validate(value, validator.keys ? _.pick(validator.schema, validator.keys) : validator.schema);
    if (Array.isArray(schemaValidated)) {
      errors = prepareErrors(schemaValidated);
    }
  }
  if (validator.rules) {
    for await (const rule of validator.rules) {
      const error = await rule(value);
      if (error) {
        if(Array.isArray(error)){
          errors = errors.concat(error);
        } else {
          errors.push(error);
        }
      }
    }
  }
  return errors;
}

export default validate;